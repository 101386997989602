import { useAuthService } from '@src/ducks/hooks';
import { largeSize, tabletSize } from "@src/utils/screensize-helper";
import { LinearGradient } from "expo-linear-gradient";
import React, { useContext } from 'react';
import { ScrollView, View } from 'react-native';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
} from "react-native-reanimated";
import AdminTabs from './AdminTabs';
import CopyRightContent from './CopyRightContent';
import DrawerList from './drawer-list';
import DrawerItem from "./drawer-list/drawer-item";
import FloatingButton from "./floating-button";
import DrawerHeader from './header';
import { DrawerComponentContext } from "./provider";
import useStyles from './styles.css';

const DrawerComponent = () => {
  const styles = useStyles();
  const isLargeSize = largeSize();
  const isTabletSize = tabletSize();
  const { isLoggedIn, onLogout } = useAuthService();
  const { animated } = useContext(DrawerComponentContext);

  const animateStyle = useAnimatedStyle((): any => {
    const translateX = interpolate(
      animated.value,
      [360, 110],
      [0, isTabletSize ? -380 : 0],
      Extrapolation.CLAMP,
    );

    const width = interpolate(
      animated.value,
      [360, 110],
      [360, isTabletSize ? 0 : isLargeSize ? 81.15 : 110],
      Extrapolation.CLAMP,
    );

    return { 
      width,
      position: isTabletSize ? "absolute" : "relative",
      transform: [{ translateX }] 
    };
  }, [isTabletSize, isLargeSize]);

  const animatePaddingLeft = useAnimatedStyle(() => {
    const paddingLeft = interpolate(
      animated.value,
      [360, 110],
      [isLargeSize ? 22.5 : 30, isLargeSize ? 11.25 : 15],
      Extrapolation.CLAMP,
    );
    return {
      paddingLeft
    };
  }, [isLargeSize]);

  return (
    <Animated.View pointerEvents="box-none" style={[styles.v_drawer_container, animateStyle ]}>
      <LinearGradient
        colors={["#343476", "#262E37"]}
        style={[styles.linear_style]}
      />

      <Animated.View style={[styles.linear_style, animatePaddingLeft]}>
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{flexGrow: 1}}>
        <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{flexGrow: 1, minHeight: "100%"}}>
          <View style={{flexGrow: 1}} >
            <DrawerHeader />
            {isLoggedIn && <DrawerList />}
            <AdminTabs />
            <View style={styles.v_height} />
          </View>
         
        </ScrollView>
          <View style={{width: "100%"}}>
            <View>
              <DrawerItem
                item="Logout"
                style={[styles.drawer_logout]}
                onPress={onLogout}
              />
            </View>
            <View style={styles.v_height} />
            <CopyRightContent />
          </View>
        </ScrollView>
      </Animated.View>
      <FloatingButton />
    </Animated.View>
  );
};

export default React.memo(DrawerComponent);
