import _ from "lodash";
import React from "react";
import { Text, View } from "react-native";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import useStyles from "./styles.css";
import Timer from "./timer";

const CELL_COUNT = 4;

type VerificationProps = {
  value: string;
  error: string;
  setValue: any;
  setResendEnable: any;
  generated: { sessionId: "" };
};

const Verification = ({value, error, setValue, generated, setResendEnable}: VerificationProps) => {
  const styles = useStyles();
  const ref = useBlurOnFulfill({value, cellCount: CELL_COUNT});
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  return (
    <View style={styles.v_upload}>
      {!_.isEmpty(error) && <Text style={styles.t_error}>The OTP you entered is incorrect. Please try again.</Text>}
      <CodeField
        ref={ref}
        {...props}
        value={value}
        cellCount={CELL_COUNT}
        onChangeText={setValue}
        keyboardType="number-pad"
        textContentType="oneTimeCode"
        renderCell={({index, symbol, isFocused}) => (
          <Text
            key={index}
            style={[styles.cell, isFocused && styles.focusCell, !_.isEmpty(error) && styles.errorCell]}
            onLayout={getCellOnLayoutHandler(index)}>
            {symbol || (isFocused ? <Cursor /> : null)}
          </Text>
        )}
      />
      <View style={styles.v_height30}/>
      <Timer sessionId={generated.sessionId} onTimeEnd={setResendEnable} />
    </View>
  );
};

export default React.memo(Verification);
