// DUCKS pattern
import { createAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@src/ducks/store';
import { DashboardValue, ErrorValue } from '../types';

export interface SignupState {
  loading: boolean;
  drawerEnable: boolean;
  data: {};
  error: ErrorValue;
  activeDrawer: string;
  documentId: string;
}

export const initialState: SignupState = {
  loading: false,
  drawerEnable: true,
  data: {},
  error: {} as ErrorValue,
  activeDrawer: "Dashboard"
} as SignupState;

// Slice
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    drawerEnable: (state, action) => {
      state.drawerEnable = action.payload;
    },
    dashboardRequest: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    dashboardSuccess: (state, action) => {
      state.data = action.payload;
      state.error = {} as ErrorValue;
      state.loading = false;
    },
    dashboardFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    activeDrawer: (state, action) => {
      state.activeDrawer = action.payload;
    },
    setDocumentId: (state, action) => {
      state.documentId = action.payload;
    },
  },
});

// Actions
// 2. Create Action
export const dashboardActions = {
  drawerEnable: dashboardSlice.actions.drawerEnable,
  dashboardRequest: createAction(
    `${dashboardSlice.name}/dashboardRequest`,
    (params: DashboardValue) => ({
      payload: params,
    })
  ),
  dashboardSuccess: dashboardSlice.actions.dashboardSuccess,
  dashboardFailure: dashboardSlice.actions.dashboardFailure,
  activeDrawer: dashboardSlice.actions.activeDrawer,
  setDocumentId: dashboardSlice.actions.setDocumentId,
};

// Selectors
export const selectedDashboard = (state: RootState) => state.dashboard.loading;
export const selectedDrawerStatus = (state: RootState) => state.dashboard.drawerEnable;
export const selectedDashboardFailed = (state: RootState) => state.dashboard.error;
export const selectedDashboardData = (state: RootState) => state.dashboard.data;
export const selectedActiveDrawer = (state: RootState) => state.dashboard.activeDrawer;
export const selectedDocumentID = (state: RootState) => state.dashboard.documentId;

// Reducer
export default dashboardSlice.reducer;
