import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import Splash from '@src/components/loading';
import AppRouting from './AppRouting';
import Linking from './Linking';

const AppNavigator = () => {
  const linking = Linking();

  return (
    <NavigationContainer linking={linking} fallback={<Splash />}>
      <AppRouting />
    </NavigationContainer>
  );
};

export default AppNavigator;
