import React, { memo } from 'react';
import {
  View,
  Text,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import TypographyStyle from '@assets/typography';
import { InputProps3 } from './types';
import { colors } from 'theme';
import _ from 'lodash';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';

function TextInput03(props: InputProps3) {
  const { label, error, disabled } = props;
  const errLabelStyle = error ? { color: colors.red } : {};
  const errBorderStyle: any = error && {
    borderColor: colors.red,
  };

  return (
    <View style={[styles.marginTop20, props.style]}>
      <View style={styles.v_label}>
        {!!label && (
          <Text style={[styles.label, props.labelStyle, errLabelStyle]}>
            {props.label}
          </Text>
        )}
        {_.isEmpty(error) ? null : (
          <Text style={[styles.txtError, props.txtErrorStyle]}>{error}</Text>
        )}
      </View>
      <TouchableOpacity
        activeOpacity={1}
        onPress={disabled ? null : props.onPress}
        style={[styles.border, props.borderStyle, errBorderStyle]}
      >
        <Text
          style={[
            styles.t_input,
            !props.value && { color: colors.placeholder },
            props.inputStyle,
          ]}
        >
          {props.value || props.placeholder}
        </Text>
        {typeof props.renderIcon === 'function' && props.renderIcon()}
        {props.rightIcon && (
          <Icon
            name={props.rightIcon as any}
            color={colors.grayPrimary}
            size={24}
          />
        )}
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  label: StyleSheet.flatten([TypographyStyle.fieldsTitle]),
  v_label: { flexDirection: 'row', justifyContent: 'space-between' },
  marginTop20: { marginTop: 20 },
  border: {
    marginTop: 8,
    paddingVertical: Platform.OS === 'web' ? 0 : 14, //FIX FOR INPUT DOING MULTILINE
    minHeight: 42,
    borderWidth: 1,
    borderRadius: 8,
    paddingRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: colors.border2,
  },
  t_input: {
    fontSize: 14,
    marginLeft: 15,
    paddingVertical: 0,
    color: colors.black,
    fontFamily: 'Roboto',
  },
  txtError: StyleSheet.flatten([
    TypographyStyle.fieldsTitle,
    {
      fontSize: 13,
      color: colors.red,
      fontStyle: 'italic',
      fontFamily: 'DMSans-Bold',
    },
  ]),
  btn_icon: { position: 'absolute', width: 24, height: 24, right: 10 },
});

export default memo(TextInput03);
