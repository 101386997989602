import React, { useRef, useState, useEffect } from "react";
import { Text } from "react-native";
import useStyles from "./styles.css";
import numeral from "numeral";

const Timer = (props: any) => {
  const styles = useStyles();
  const timerId = useRef<any>(null);
  const [timer, setTimer] = useState<number | null>(null);

  const calculateTimeLeft = () => {
    const sessionIdInMs = props.sessionId * 1000;
    const now = Date.now();
    const timeLeftInSeconds = Math.floor((sessionIdInMs - now) / 1000);

    return timeLeftInSeconds;
  };

  useEffect(() => {
    if (props.sessionId) {
      const timeLeft = calculateTimeLeft();
      
      if (timeLeft > 0) {
        setTimer(timeLeft);
      } else {
        setTimer(0);
        props.onTimeEnd && props.onTimeEnd(true);
      }
    }
  }, [props.sessionId]);

  useEffect(() => {
    if (timer === null || timer <= 0) {
      return;
    }

    // Set interval to count down every second
    timerId.current = setInterval(() => {
      setTimer((lastTimer) => {
        const newTimer = lastTimer !== null ? lastTimer - 1 : null;
        if (newTimer !== null && newTimer <= 0) {
          clearInterval(timerId.current);
          props.onTimeEnd && props.onTimeEnd(true);
        }
        return newTimer;
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timerId.current);
  }, [timer]);

  if (timer === null || timer <= 0) {
    return (
      <Text style={[styles.t_error]}>OTP is expired.</Text>
    );
  }

  return (
    <Text style={[styles.t_error]}>
      {numeral(timer).format("00:00")}
    </Text>
  );
};

export default Timer;
