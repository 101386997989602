import React from 'react';
import { ScrollView, Text, View, StyleSheet } from 'react-native';
import { colors } from 'theme';
import useStyles from './styles.css';

const TermsAndConditions = () => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Text style={[styles.text, styles.t_first_paragraph]}>
        The Terms and Conditions (the “Terms”) on this page provide for the conditions, restrictions, and terms under which you can use the website (the “Website”) and the services or products provided by UNAWA. By availing of any services or products from UNAWA, or by using the Website, you indicate that you consent to and accept these Terms and agree to be bound by and abide by them.
      </Text>

      <Text style={styles.subHeader}>1. UNAWA</Text>
      <Text style={styles.text}>
        1.1.	UNAWA Inc. (“UNAWA”) is an entity duly organized under the laws of the Philippines and registered with the Securities and Exchange Commission, with business address at 10F 8 Rockwell, Hidalgo corner Plaza Drives, Rockwell Center, Makati City, Philippines.
      </Text>

      <Text style={styles.subHeader}>2. Use of Website</Text>
      <Text style={styles.text}>
        2.1.	Persons visiting the Website or availing of services or products through it (the “Users”) understand that the Website may be used for the following legitimate purposes:
      </Text>
      <View style={styles.subSection}>
        <Text style={styles.text}>
          2.1.1.	To obtain more information about UNAWA, the company, its services or products, its activities, its social media presence, its news and updates, its affiliates, and other related information;
        </Text>
        <Text style={styles.text}>
          2.1.2.	To avail of its services or products, which may require Users to submit data and documents to facilitate the service and make payments to UNAWA through payment gateways;
        </Text>
        <Text style={styles.text}>
          2.1.3.	To contact UNAWA with queries, concerns, clarifications, which may require Users to submit their personal information and contact details; and
        </Text>
        <Text style={styles.text}>
          2.1.4. Any other related or analogous purpose.
        </Text>
      </View>
      <Text style={styles.text}>
        2.2.	Users shall not use the website in any manner that is contrary to any law, statute, regulation, or issuance of a government authority of the Philippines, or of the jurisdiction where the User is located, including those pertaining to intellectual property or cybersecurity.
      </Text>
      <Text style={styles.text}>
        2.3.	Users shall not misuse and abuse services and products offered by UNAWA. This includes doing actions beyond the intended use of UNAWA’s services and products.
      </Text>
      <Text style={styles.text}>
        2.4.	Users understand that their use of the Website and UNAWA services and products is through their own software, devices, and network. UNAWA does not warrant the compatibility of the Website or its services and products and the computer equipment, devices, software, or platform through which they are accessed by Users. Thus, UNAWA shall not be liable for any damage that may arise from any incompatibility or loss of functionality. UNAWA likewise shall not be liable for any damage to such equipment, devices, software, or platform that results from your access to or use of the Website or UNAWA services and products.
      </Text>
      <Text style={styles.text}>
        2.5.	The Website may link to external websites, including social media platforms on which UNAWA maintains official accounts and websites of UNAWA affiliates. UNAWA shall not be responsible for content on external websites that it has not created, generated, or consented to being associated with.
      </Text>
      <Text style={styles.text}>
        2.6.	Users are responsible for maintaining the confidentiality of their account credentials, including username and password. Any unauthorized access or use of the account due to a User’s failure to safeguard such information shall not hold UNAWA liable for damages or losses incurred.
      </Text>
      <Text style={styles.text}>
        2.7.	Users must immediately notify UNAWA of any unauthorized access to their account or suspicious activity.
      </Text>

      <Text style={styles.subHeader}>3.	Availment of Services or Products</Text>
      <Text style={styles.text}>
        3.1.	Users understand that UNAWA is not engaged in the practice of law. Users likewise understand that UNAWA does not furnish binding legal advice through the provision of its services and products, and no lawyer-client relationship is established between UNAWA and the Users.
      </Text>
      <View style={styles.subSection}>
        <Text style={styles.text}>
        3.1.1.	UNAWA does not guarantee the intrinsic validity of any contracts, agreements, and documents processed through its services and products. 
        </Text>
      </View>
      <Text style={styles.text}>
        3.2.	The time periods indicated in this Website with respect to the provision of services may be affected by the following:
      </Text>
      <View style={styles.subSection}>
        <Text style={styles.text}>3.2.1. Processes of relevant government authorities;</Text>
        <Text style={styles.text}>3.2.2. Deficiencies in documents or submissions; or</Text>
        <Text style={styles.text}>
          3.2.3. Force majeure or any other unforeseeable and unavoidable causes
          unattributable to UNAWA.
        </Text>
        <Text style={styles.text}>
          3.2.4. Such periods must therefore be taken as indicative. Should the
          processing for transactions exceed these indicative periods, it cannot
          be taken as a breach upon which UNAWA may be made liable.
        </Text>
      </View>
      <Text style={styles.text}>
        3.3. UNAWA shall not be liable for any failure to perform its
        obligations under these Terms if such failure is due to circumstances
        beyond its reasonable control, including but not limited to acts of God,
        war, terrorism, strikes, internet outages, pandemics, or other similar
        events (“Force Majeure”). Performance shall be suspended for the
        duration of the Force Majeure event, and both parties shall make
        reasonable efforts to mitigate its effects.
      </Text>
      <Text style={styles.text}>
        3.4. In availing of UNAWA services or products, Users may be required to
        submit data or provide documents that contain personal information as
        defined under the Data Privacy Act of 2012, its Implementing Rules and
        Regulations, issuances of the National Privacy Commission, and other
        relevant data protection laws and issuances (the “Philippine Privacy
        Laws”). For more information on how UNAWA processes Users’ personal
        information, please refer to our Privacy Policy.
      </Text>
      <Text style={styles.text}>
        3.5. The Users acknowledge that the data, information, documents, and
        other related files which are uploaded in UNAWA’s services or products
        are to be stored and processed in UNAWA’s servers. Thus, Users give
        UNAWA permission to store and process them as UNAWA sees fit in order to
        provide its services.
      </Text>
      <Text style={styles.text}>
        3.6. Payments for availing of services or products must be made through
        the payment gateways indicated on the Website. Users agree to provide
        accurate and complete payment information.
      </Text>
      <Text style={styles.text}>
        3.7. UNAWA reserves the right to suspend or terminate services to Users
        who fail to make full payments on time.
      </Text>
      <Text style={styles.text}>
        3.8. Refund requests will be evaluated on a case-by-case basis and will
        be processed according to UNAWA’s refund policy.
      </Text>
      <Text style={styles.subHeader}>4. Obligations of UNAWA</Text>
      <Text style={styles.text}>
        4.1.	In providing its services and products, UNAWA shall:
      </Text>
      <View style={styles.subSection}>
        <Text style={styles.text}>
          4.1.1.	Observe all obligations under Philippine Privacy Laws in the processing of personal information from Users. For more information on how UNAWA processes Users’ personal information, please refer to our Privacy Policy.
        </Text>
        <Text style={styles.text}>
          4.1.2.	Comply with requirements of cybersecurity under relevant Philippine laws and under globally acceptable standards.
        </Text>
        <Text style={styles.text}>
          4.1.3.	Maintain reasonable provisions for the scalability, security, and resilience of relevant software and platforms.
        </Text>
        <Text style={styles.text}>
          4.1.4.	The Website may use cookies and similar technologies to enhance the user experience, analyze website traffic, and facilitate targeted advertising. Users can manage their cookie preferences via their browser settings. For more information on how cookies are used, please refer to our Cookie Policy.
        </Text>
      </View>
      <Text style={styles.subHeader}>5.	Termination Clause</Text>
      <Text style={styles.text}>
        5.1.	UNAWA reserves the right to terminate or suspend the account of Users it has found to have violated the Terms and Conditions in this document. 
      </Text>
      <Text style={styles.text}>
        5.2.	Users, in writing, may request for the reactivation of the terminated or suspended account. The written document must specify any justifications that would support the reactivation of said account.
      </Text>
      <Text style={styles.text}>
        5.3.	Users, in writing, may request for the deactivation of their account and the deletion of any related data, information, and files, specifying the reasons for such request. 
      </Text>
      <View style={styles.subSection}>
        <Text style={styles.text}>
          5.3.1.	UNAWA shall process such request in compliance with the Data Privacy Act of 2012 and its Implementing Rules and Regulations.
        </Text>
        <Text style={styles.text}>
          5.3.2.	UNAWA shall process such request within a time period which is technically feasible, considering the amount of data to be removed, the number of ongoing requests, and other analogous factors
        </Text>
      </View>
      <Text style={styles.subHeader}>6.	General Provisions</Text>
      <Text style={styles.text}>
        6.1.	In order to avail of UNAWA’s services or products, the Users must accept the Terms and Conditions set out in this document. Users are considered to have accepted the Terms by using the services and products of UNAWA.
      </Text>
      <Text style={styles.text}>
        6.2.	UNAWA reserves the right to amend these Terms at any time without prior notice. However, Users will be notified of such changes via email or other means of communication if they hold an active account. Continued use of the Website or UNAWA’s services following the amendment constitutes acceptance of the revised Terms.
      </Text>
      <Text style={styles.text}>
        6.3.	UNAWA shall retain ownership of all its intellectual property, and no use thereof may be carried out without UNAWA’s prior consent and approval in writing. 
      </Text>
      <View style={styles.subSection}>
        <Text style={styles.text}>
          6.3.1.	In turn, Users shall retain ownership over intellectual property that may be processed through UNAWA, such as logos, trade names, marks, or other intellectual property.
        </Text>
      </View>
      <Text style={styles.text}>
        6.4.	UNAWA’s failure or delay in enforcing any right under these Terms shall not be construed as a waiver or limitation of its right to subsequently enforce these Terms and compel strict compliance therewith.
      </Text>
      <Text style={styles.text}>
        6.5.	Should any part of these Terms be declared as invalid, unenforceable, or in conflict with the applicable laws or regulations of any jurisdiction, such invalidity, unenforceability, or conflict shall in no way affect the rest of the Terms, which shall remain in full force and effect and binding upon UNAWA and the User.
      </Text>
      <Text style={styles.text}>
        6.6.	Nothing in these Terms shall create or be deemed to create a partnership, agency, or employer-employee relationship between UNAWA and any User.
      </Text>
      <Text style={styles.text}>
        6.7.	These Terms, as well as any obligations that may arise from them, shall be governed and construed in accordance with Philippine laws. Any dispute that may result from these terms shall be submitted to the exclusive jurisdiction of the courts of Makati City, Metro Manila, Philippines only.
      </Text>
      <Text style={styles.text}>
        6.8.	UNAWA makes no warranty that the Website, services, or products will be error-free, uninterrupted, or secure. Users acknowledge that use of the Website and services is at their own risk. UNAWA shall not be liable for any technical malfunctions, disruptions, or losses, including data loss or corruption resulting from third-party service providers.
      </Text>
      <Text style={styles.text}>
        6.9.	To the extent permitted by law, UNAWA’s total liability to any User for all claims arising from or related to the use of the Website or services is limited to the fees paid by the User for the relevant service or product in the three (3) months preceding the event that gave rise to the liability. UNAWA will not be liable for any indirect, incidental, consequential, or punitive damages.
      </Text>
      <Text style={styles.text}>
        6.10.	Users agree to indemnify and hold harmless UNAWA, its officers, directors, employees, and affiliates, from and against any claims, liabilities, damages, losses, or expenses, including legal fees, arising out of or related to (a) the User’s violation of these Terms, (b) misuse of the Website or services, or (c) breach of any applicable law or regulation.
      </Text>
    </View>
  );
};

export default TermsAndConditions;
