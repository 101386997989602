import React, { useState, useCallback, createContext, useContext, Dispatch, SetStateAction } from 'react';
import {
  selectUserCity,
  selectUserEmail,
  selectUserFullname,
} from '@src/ducks/slices/user.slice';
import { useAppSelector } from '@src/ducks/ducksHook';
import { FileValue, ProviderProps } from './types';
import { nanoid } from '@reduxjs/toolkit';
import colors from '@assets/colors';
import _ from 'lodash';
import { useDashboardService } from '@src/ducks/hooks';

export interface ContextValue {
  visible: boolean;
  file: FileValue;
  resetFile: any;
  screen: string;
  selectedNotary: any;
  setSelectedNotary: Dispatch<SetStateAction<any>>;
  onUpdateScreen: (value: string) => void;
  onOpenUpload: () => void;
  onSelectFile: (value: any) => void;
  onUpdateFile: (key: string) => (value: any) => void;
  enablePayment: boolean;
  setEnablePayment: Dispatch<SetStateAction<boolean>>;
}

const DocumentContext = createContext<ContextValue>({} as ContextValue);

const DocumentProvider = ({ children }: ProviderProps) => {
  const city = useAppSelector(selectUserCity);
  const name = useAppSelector(selectUserFullname);
  const email = useAppSelector(selectUserEmail);

  const initialFileData = {
    notarialAct: '',
    qrposition: 'Bottom right',
    meridiem: "AM",
    parties: [
      {
        name: '',
        email: '',
        trackId: nanoid(),
        role: 'SIGN',
        ref: React.createRef(),
        fill: colors.signature3,
        roleType: 'Principal'
      },
      {
        trackId: nanoid(),
        name: '',
        email: '',
        role: 'SIGN',
        ref: React.createRef(),
        fill: colors.signature4,
        roleType: 'Witness'
      },
    ],
  };

  const { onDrawerEnable } = useDashboardService();
  const [file, setFile] = useState<any>(initialFileData);
  const [screen, setScreen] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedNotary, setSelectedNotary] = React.useState(null);
  const [enablePayment, setEnablePayment] = useState<boolean>(false);

  console.log("filefilefilefilefile", file)

  const onUpdateScreen = useCallback(setScreen, [screen]);

  const onSelectFile = useCallback(
    (value: any) => {
      if (_.isEmpty(value)) {
        setFile({
          ...file,
          uri: null,
        });

        return;
      }

      const author = {
        name: name,
        email: email,
        role: 'SIGN',
        trackId: nanoid(),
        fill: colors.signature1,
        roleType: 'Principal'
      };

      setFile({
        ...file,
        size: value.size,
        name: value.name,
        subject: `Please sign ${value.name}`,
        type: value.mimeType,
        uri: value.uri,
        ref: React.createRef(),
        trackId: nanoid(),
        city,
        selectedUser: author,
        author: author,
        documentType: ''
      });
    },
    [file, city, name, email]
  );

  const resetFile = () => {
    setFile(initialFileData);
  };

  const onUpdateFile = useCallback(
    (key: string)  => (value: any) => {
      if (key === 'details') {
        setFile((prevData: any) => ({ ...prevData, ...value }));
      } else {
        setFile((prevData: any) => ({ ...prevData, [key]: value }));
      }
    },
    [file]
  );

  const onOpenUpload = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const value = {
    visible,
    file,
    screen,
    onUpdateScreen,
    onOpenUpload,
    onSelectFile,
    onUpdateFile,
    resetFile,
    selectedNotary,
    setSelectedNotary,
    enablePayment, setEnablePayment
  };

  React.useEffect(() => {
    onDrawerEnable(false);
  },[]);

  return (
    <DocumentContext.Provider value={value}>
      {children}
    </DocumentContext.Provider>
  );
};

export const useDocumentContext = () => {
  return useContext(DocumentContext);
};

export { DocumentProvider, DocumentContext };
