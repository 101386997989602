import colors from "@assets/colors";
import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    modal_container: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.blurBG,
    },
    modal_body: {
      width: 600,
      height: 420,
      borderRadius: 5,
      backgroundColor: colors.white,
      alignItems: 'center',
      justifyContent: 'center',
    },
    btn_mask: {
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
    container: { alignItems: "center" },
    t_title: {
      color:colors.black3, 
      fontSize: 18,  
      fontFamily: 'Montserrat-Bold',
      marginBottom: 20
    },
    t_subtitle: {
      color:colors.black3, 
      fontSize: 16,
      lineHeight: 17,
      fontFamily: 'Montserrat',
      fontWeight: '400',
      marginBottom: 10
    },
    v_upload: {
      height: 50, 
      width: 711, 
      borderRadius: 18,
      alignItems: "center",
      justifyContent: "center",
      marginTop: 50,
    },
    v_height30: { height: 30 },
    btn_upload: {
      height: 47,
      width: 150,
      marginTop: 40,
      borderRadius: 8,
    },
    label_upload: { fontFamily: "DMSans-Bold", fontSize: 14, lineHeight: 14, color: colors.white},
    divider: { width: 15 },
    labelStyle: {
      fontFamily: "DMSans-Bold"
    },
    btn_submit: {
      width: 183,
      height: 43,
      backgroundColor: colors.purple,
      borderRadius: 6.81
    },
    btn_resend: {
      width: 183,
      height: 43,
      borderRadius: 6.81,
      backgroundColor: colors.transparent,
      borderWidth: 1,
      borderColor: colors.purple
    },
    label_resend: {
      fontFamily: "DMSans-Bold",
      color: colors.purple
    },
    // VERIFICATION
    cell: {
      width: 47,
      height: 42,
      marginRight: 25,
      fontSize: 24,
      borderRadius: 5,
      borderWidth: 1,
      textAlign: "center",
      borderColor: colors.purple1,
    },
    focusCell: {
      borderColor: colors.black3,
    },
    errorCell: {
      borderColor: colors.red,
    },
    t_error: {
      ...TypographyStyle.Body2Regular, 
      color: colors.red,
      marginBottom: 10
    },
  },
  {
  }
);

export default useStyles;
