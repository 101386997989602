import { useCallback } from 'react';
import { useAppDispatch } from '../ducksHook';
import {
  dashboardActions
} from '../slices/dashboard.slice';

import { DashboardValue } from '../types';

type Operators = {
  onDrawerEnable: (params: boolean) => void;
  onDashboard: (params: DashboardValue) => void;
  onActiveDrawerChange: (params: string) => void;
};

export const useDashboardService = (): Readonly<Operators> => {
  const dispatch = useAppDispatch();

  return {
    onDashboard: useCallback((params: DashboardValue) => {
      dispatch(dashboardActions.dashboardRequest(params));
    }, [dispatch]),
    onDrawerEnable: useCallback((params: any) =>  
      dispatch(dashboardActions.drawerEnable(params)),
    [dispatch]),
    onActiveDrawerChange: useCallback((params) => 
      dispatch(dashboardActions.activeDrawer(params)),
    [dispatch]),
  };
};

export default useDashboardService;