/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View, Text, Image } from 'react-native';
import { Button01 } from '@src/components/button';
import Background from '../signin/background';
import useStyles from './styles.css';
import images from '@assets/images';
import Header from './header';
import { useNavigation } from '@react-navigation/native';

const VerificationSuccess = (props: any) => {
  const styles = useStyles();
  const title = 'Account verified';
  const notes =
    'Your email has been verified. Start accessing your documents using your account.';
  const btnLabel = 'Go to login';
  const navigation = useNavigation<any>();

  const onPress = () => {
    navigation.navigate('Signin');
  };

  return (
    <View style={styles.container}>
      <Background />
      <Header />
      <View style={styles.v_body}>
        <Image
          style={styles.i_style}
          source={images.check}
          resizeMode="stretch"
        />
        <Text style={styles.t_title}>{title}</Text>
        <Text style={[styles.t_note, { marginTop: 10 }]}>{notes}</Text>
        <Button01
          onPress={onPress}
          style={styles.btn_dashboard}
          label={btnLabel}
        />
      </View>
    </View>
  );
};

export default VerificationSuccess;
