import React from "react";
import { DrawerIconProps } from "@src/components/icon/interface";
import Dashboard from "./dashboard.svg";
import DigitalNotarialBook from "./digital-notarial-book.svg";
import FAQ from "./faq.svg";
import Inbox from "./inbox.svg";
import LiveNotarizationRequests from "./live-notarization-requests.svg";
import Schedule from "./schedule.svg";
import Signatures from "./signatures.svg";
import Support from "./support.svg";
import Transactions from "./transactions.svg";
import Applications from "./applications.svg";
import PartnerNotaries from "./partner-notaries.svg";
import Documents from "./documents.svg";
import ManageUsers from "./manageusers.svg";
import ManageGroups from "./managegroups.svg";
import AuditLog from "./auditlog.svg";
import Logout from "./logout.svg";
import colors from "@assets/colors";

const DrawerIcon = (props: DrawerIconProps) => {
  const { name, isActive, width, height } = props;
  const fill = isActive ? colors.purple : colors.white;

  switch (name) {
  case "Dashboard":
    return <Dashboard fill={fill} width={width || 22} height={height || 20} />;
  case "FAQs":
    return <FAQ fill={fill} width={width || 22} height={height || 20} />;
  case "Inbox":
    return <Inbox fill={fill} width={width || 20} height={height || 28} />;
  case "Notarization Requests":
    return <LiveNotarizationRequests fill={fill} width={width || 23} height={height || 21} />;
  case "Signatures":
    return <Signatures fill={fill} width={width || 24} height={height || 24} />;
  case "Support":
    return <Support fill={fill} width={width || 21} height={height || 21} />;
  case "Transactions":
    return <Transactions fill={fill} width={width || 21} height={height || 21} />;
  case "Partner Notaries":
    return <PartnerNotaries fill={fill} width={width || 21} height={height || 21} />;
  case "Schedule":
    return <Schedule fill={fill} width={width || 21} height={height || 21} />;
  case "Digital Notarial Book":
    return <DigitalNotarialBook fill={fill} width={width || 21} height={height || 21} />;
  case "Applications":
    return <Applications stroke={fill} width={width || 21} height={height || 21} />;
  case "Documents":
    return <Documents fill={fill} width={width || 21} height={height || 21} />;
  case "Manage Users":
    return <ManageUsers fill={fill} width={width || 21} height={height || 21} />;
  case "Manage Groups":
    return <ManageGroups fill={fill} width={width || 21} height={height || 21} />;
  case "Audit Log":
    return <AuditLog fill={fill} width={width || 21} height={height || 21} />;
  case "Logout":
    return <Logout fill={fill} width={width || 20} height={height || 28} />;
  }
};

export default DrawerIcon;