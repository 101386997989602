import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../ducksHook';
import {
  authActions,
  selectAuthLoggingIn,
  selectAuthLogInFailed,
  selectedAuthAccessToken,
  selectAuthLoggedIn,
  selectedAuthUserID,
  selectAuthLoginInput,
} from '../slices/auth.slice';

// Types
import { ErrorValue, TypeValue, loginInputValue } from '../types';

export type AuthServiceOperators = {
  failed: ErrorValue;
  userId: string;
  accessToken: string;
  isLoading: boolean;
  isLoggedIn: boolean;
  loginInput: loginInputValue;
  onLogin: (params: any) => void;
  onLogout: () => void;
  onSignin: () => void;
  onRefreshToken: () => void;
  onResetLoading: () => void;
  setLoginInput: (params: TypeValue) => void;
  onFaceIDLogin: () => void;
  onOtpSuccess: () => void;
};

export const useAuthService = (): Readonly<AuthServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    failed: useAppSelector(selectAuthLogInFailed),
    isLoading: useAppSelector(selectAuthLoggingIn),
    isLoggedIn: useAppSelector(selectAuthLoggedIn),
    userId: useAppSelector(selectedAuthUserID),
    accessToken: useAppSelector(selectedAuthAccessToken),
    loginInput: useAppSelector(selectAuthLoginInput),
    onLogin: useCallback((params: any) => {
      dispatch(authActions.loginSuccess(params));
    }, [dispatch]),
    onSignin: useCallback(() => {
      dispatch(authActions.loginRequest());
    }, [dispatch]),
    onLogout: useCallback(() => {
      dispatch(authActions.logout());
    }, [dispatch]),
    onRefreshToken: useCallback(() => {
      dispatch(authActions.refreshTokenRequest());
    }, [dispatch]),
    onResetLoading: useCallback(() => {
      dispatch(authActions.resetLoading());
    }, [dispatch]),
    setLoginInput: useCallback((params: TypeValue) => {
      dispatch(authActions.setLoginInput(params));
    }, [dispatch]),
    onFaceIDLogin: useCallback(() => {
      dispatch(authActions.faceIDloginRequest());
    },[dispatch]),
    onOtpSuccess: useCallback(() => {
      dispatch(authActions.otpSuccess());
    }, [dispatch])
  };
};

export default useAuthService;
