import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      padding: 24,
    },
    subHeader: {
      fontSize: 18,
      fontWeight: 'bold',
      marginTop: 16,
      color: colors.black5,
      lineHeight: 24,
      marginBottom: 4,
      fontFamily: 'DMSans-Medium'
    },
    t_first_paragraph: {
      paddingLeft: 0,
    },
    text: {
      paddingLeft: 16,
      fontSize: 18,
      marginBottom: 4,
      lineHeight: 24,
      fontFamily: 'DMSans',
      color: colors.black5,
    },
    subSection: {
      paddingLeft: 16,
    },
  },
  {
  }
);

export default useStyles;
