// DUCKS pattern
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@src/ducks/store';
import moment from 'moment';
import {
  CreateDocumentRequestValue,
  CreateLiveRequestValue,
  ErrorValue,
} from '../types';

export interface initialStateValue {
  loading: boolean;
  documentCreated: boolean;
  document: CreateDocumentRequestValue;
  error: ErrorValue;
  affixSignature: CreateDocumentRequestValue;
  currentLoading: string;
  enableLiveSessionRefresh: boolean;
}

export const initialState: initialStateValue = {
  loading: false,
  document: {},
  error: {} as ErrorValue,
  enableLiveSessionRefresh: false
} as initialStateValue;

// Slice
export const signsecureSlice = createSlice({
  name: 'signsecure',
  initialState: initialState,
  reducers: {
    // Create Request
    createLiveRequest: (state) => {
      state.loading = true;
      state.documentCreated = false;
    },
    createContactlessRequest: (state) => {
      state.loading = true;
      state.documentCreated = false;
    },
    createLiveRequestSuccess: (state, action) => {
      state.loading = false;
      state.documentCreated = action.payload;
    },
    createLiveRequestFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    // View Document
    viewDocumentRequest: (state, action) => {
      state.affixSignature = {} as CreateDocumentRequestValue;
      state.document = action.payload;
      state.error = {} as ErrorValue;
      state.loading = true;
    },
    viewDocumentSuccess: (state, action) => {
      state.document = action.payload;
      state.loading = false;
    },
    viewDocumentFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    // affixing signatures
    affixSignatureRequest: (state) => {
      state.loading = true;
      state.currentLoading = 'Signing';
    },
    affixSignatureSuccess: (state, action) => {
      state.affixSignature = action.payload;
      state.currentLoading = '';
      state.loading = false;
    },
    affixSignatureFailed: (state, action) => {
      state.error = action.payload;
      state.currentLoading = '';
      state.loading = false;
    },

    // Payment request
    contactlessPaymentRequest: (state) => {
      state.loading = true;
    },
    contactlessPaymentSuccess: (state) => {
      state.loading = false;
    },

    resetError: (state) => {
      state.error = {} as ErrorValue;
    },
    updateSignatures: (state, action) => {
      state.document = action.payload;
    },

    liveSessionRefreshStatus: (state, action) => {
      state.enableLiveSessionRefresh = action.payload;
    },

    //reset state
    resetSignSecure: () => {
      return initialState;
    },
  },
});

// Actions
export const signsecureActions = {
  createLiveRequest: createAction(
    `${signsecureSlice.name}/createLiveRequest`,
    (params: CreateLiveRequestValue) => ({
      payload: params,
    })
  ),
  createContactlessRequest: createAction(
    `${signsecureSlice.name}/createContactlessRequest`,
    (params: CreateLiveRequestValue) => ({
      payload: params,
    })
  ),
  createLiveRequestSuccess: signsecureSlice.actions.createLiveRequestSuccess,
  createLiveRequestFailed: signsecureSlice.actions.createLiveRequestFailed,
  
  viewDocumentRequest: signsecureSlice.actions.viewDocumentRequest,
  viewDocumentSuccess: signsecureSlice.actions.viewDocumentSuccess,
  viewDocumentFailed: signsecureSlice.actions.viewDocumentFailed,

  affixSignatureRequest: signsecureSlice.actions.affixSignatureRequest,
  affixSignatureSuccess: signsecureSlice.actions.affixSignatureSuccess,
  affixSignatureFailed: signsecureSlice.actions.affixSignatureFailed,

  contactlessPaymentRequest: createAction(
    `${signsecureSlice.name}/contactlessPaymentRequest`,
    (params: any) => ({ payload: params })
  ),
  contactlessPaymentSuccess: signsecureSlice.actions.contactlessPaymentSuccess,

  resetError: signsecureSlice.actions.resetError,
  updateSignatures: signsecureSlice.actions.updateSignatures,

  resetSignSecure: signsecureSlice.actions.resetSignSecure,
  liveSessionRefreshStatus: signsecureSlice.actions.liveSessionRefreshStatus,
  refreshDocumentRequest: createAction(`${signsecureSlice.name}/refreshDocumentRequest`),
};

// Selectors
export const selectedCreateRequestLoading = (state: RootState) => state.signsecure.loading;
export const selectedSignSecureLoadingStatus = (state: RootState) => state.signsecure.currentLoading;
export const selectedDocumentCreated = (state: RootState) => state.signsecure.documentCreated;
export const selectedViewDocument = (state: RootState) => state.signsecure.document;
export const selectedSignSecureError = (state: RootState) => state.signsecure.error;
export const selectedAffixDocument = (state: RootState) => state.signsecure.affixSignature;

export const selectedCustomSignatureID = createSelector(
  (state: RootState) => state.auth.session,
  (session) => {
    const userId = session?.id ?? "";
    const last2Digit = userId.substr(userId.length -4);
    return `${last2Digit}${moment().format("YYMMDDhhmmss")}`;
  }
);
export const selectedDocumentParties = createSelector(
  (state: any) => state.signsecure.document || {},
  (state: any) => state.auth.session,
  (document, session) => {
    const email = session?.email ?? "";
    const parties = document?.workflow?.parties ?? [];

    return parties.find((item: any) => item.email === email) || {};
  }
);
export const selectedEnableSignature = createSelector(
  (state: any) => state.signsecure.document || {},
  (state: any) => state.auth.session,
  (document, session) => {
    const email = session?.email ?? "";
    const status = document?.workflow?.status ?? "";
    const parties = document?.workflow?.parties ?? [];
    const signOrder = document?.workflow?.signOrder ?? false;
    const currentOrder = document?.workflow?.currentOrder ?? 0;
    const Transaction = ["DECLINED", "REJECTED", "CANCELLED", "COMPLETED"];

    if(signOrder){
      const currentParty = parties[currentOrder] || {};
      if(currentParty.email === email && (!Transaction.includes(status) && currentParty.status !== "SIGNED")){
        return true;
      }
    }else{
      const currentSigner = parties.find((item: any) => item.email === email) || {};
      if(!Transaction.includes(status) && currentSigner.status !== "SIGNED"){
        return true;
      }
    }

    return false;
  }
);


// Reducer
export default signsecureSlice.reducer;
