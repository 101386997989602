import React, { useState, useEffect, useCallback, createContext, useContext } from "react";
import { selectedDrawerStatus } from "@src/ducks/slices/dashboard.slice";
import { withTiming, useSharedValue } from "react-native-reanimated";
import { tabletSize } from "@src/utils/screensize-helper";
import { useDashboardService } from "@src/ducks/hooks";
import { useAppSelector } from "@src/ducks/ducksHook";

interface ContextValue {
  animated: any;
  isMinimize: boolean;
  onMinimizeState: () => void;
}

const DrawerComponentContext = createContext<ContextValue>({
  isMinimize: false,
} as ContextValue);

interface ProviderProps {
  children: React.ReactElement;
}

const DrawerComponentProvider = ({ children }: ProviderProps) => {
  const isTabletSize = tabletSize();
  const { onDrawerEnable } = useDashboardService();
  const [isMinimize, setMinimize] = useState(false);
  const animated = useSharedValue(isTabletSize ? 0 : 360);
  const drawerEnable = useAppSelector(selectedDrawerStatus);

  const onMinimizeState = useCallback(
    () => {
      setMinimize(!isMinimize);
      onDrawerEnable(isMinimize);
    },
    [isMinimize]
  );

  useEffect(() => {
    animated.value = withTiming(isMinimize ? 110 : 360, { duration: 400 });
  }, [isMinimize]);

  useEffect(() => {
    setMinimize(!drawerEnable);
  }, [drawerEnable]);

  useEffect(() => {
    setMinimize(isTabletSize);
    onDrawerEnable(!isTabletSize);
  }, [isTabletSize]);

  const value = {
    animated,
    isMinimize,
    onMinimizeState,
  };

  return (
    <DrawerComponentContext.Provider value={value}>
      {children}
    </DrawerComponentContext.Provider>
  );
};

export const useDrawerComponentContext = () => {
  return useContext(DrawerComponentContext);
}

export { DrawerComponentProvider, DrawerComponentContext };
