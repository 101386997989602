import React from 'react';
import { ScrollView, Text, StyleSheet, View } from 'react-native';
import useStyles from './styles.css';

const PrivacyPolicy = () => {
  const styles = useStyles();
  return (
    <View style={styles.container}>
      <Text style={styles.paragraph}>
        We at UNAWA are committed to protecting and respecting your privacy by
        collecting and processing your personal information and sensitive
        personal information in accordance with applicable data privacy laws
        and regulations (“Data Privacy Laws”), including the Philippine Data
        Privacy Act of 2012 (“DPA”) and its implementing rules and regulations
        (“DPA IRR”) (collectively, the “Privacy Laws”).
      </Text>
      <Text style={styles.paragraph}>
        This Privacy Notice sets out the manner by which UNAWA collects and
        processes your personal data.
      </Text>
      <Text style={styles.paragraph}>
        This Privacy Notice has been prepared and used as of January 2020 (“Notice Date”). We reserve the right to amend this Privacy Notice from time to time to reflect changing legal requirements or our processing practices. Any such changes will be posted on this website and will be effective upon posting. This privacy notice was updated 
      </Text>

      <Text style={styles.subheading}>The Personal Information We Collect And Process</Text>
      <Text style={styles.paragraph}>
        As of the Notice Date, the personal information we collect and process from you as a data subject may include: 
      </Text>
      <View style={styles.list}>
        <Text style={styles.listItem}>a.	Information that will allow us to contact you, such as your name, address, email address, and telephone and mobile numbers; </Text>
        <Text style={styles.listItem}>b.	A copy of your electronic signature</Text>
        <Text style={styles.listItem}>c.	Information that will allow us to verify your identity, such as government-issued identification number and details, e.g. social security numbers, tax identification numbers, and passport details; and </Text>
        <Text style={styles.listItem}>d.	Other personal information that will be necessary to assist us in providing our services to you. </Text>
      </View>

      <Text style={styles.subheading}>How We Collect Personal Information</Text>
      <Text style={styles.paragraph}>
        In order to provide our services to you, we will collect personal data directly from you or your organization when you or your organization:
      </Text>
      <View style={styles.list}>
        <Text style={styles.listItem}>a.	Register and create an account to use our services;</Text>
        <Text style={styles.listItem}>b.	Engage in the use of our services;</Text>
        <Text style={styles.listItem}>c.	Access, browse, visit, use, or contact us through our website and other online or social media platform;</Text>
        <Text style={styles.listItem}>d.	Contact us or provide such personal information to us in other circumstances.</Text>
      </View>
      
      <Text style={styles.paragraph}>
        In some cases, we may collect data about you from third-party sources, such as from public records. 
      </Text>

      <Text style={styles.subheading}>Purpose And Manner Of Using Personal Information </Text>
      <Text style={styles.paragraph}>
        Any personal information we collect are manually and/or electronically used, stored, recorded, or otherwise processed exclusively for purposes of: 
      </Text>
      <View style={styles.list}>
        <Text style={styles.listItem}>a.	Rendering services to you or your organization, as clients; </Text>
        <Text style={styles.listItem}>b.	Conveying information on regulatory updates, legal matters, or other compliance matters relevant to you and/or your organization or business; </Text>
        <Text style={styles.listItem}>c.	Managing our business relationship with you and/or your organization, including processing payments, accounting, auditing, billing and collection and related support services; </Text>
        <Text style={styles.listItem}>d.	Acting in compliance with our legal obligations, including with respect to anti-money laundering and sanctions checks; </Text>
        <Text style={styles.listItem}>e.	Managing and securing the access to our offices, systems and online platforms; </Text>
        <Text style={styles.listItem}>f.	Complying with legal and regulatory requirements, including mandatory regulatory submissions and court orders as may be applicable; and </Text>
        <Text style={styles.listItem}>g.	Any other purpose related to the foregoing or for any purpose for which you provided your personal information to UNAWA. </Text>
      </View>

      <Text style={styles.paragraph}>
      Your personal information will be disclosed to or shared with the following entities, for the purpose of providing additional legal review or advice on the particular services that you have availed from UNAWA: 
      </Text>
      <View style={styles.list}>
        <View style={styles.list}>
          <Text style={styles.listItem}>
            a.	Puyat Jacinto & Santos (PJS) Law; 
          </Text>
          <Text style={styles.listItem}>
            b.	PJS Corporate Support, Inc.; 
          </Text>
          <Text style={styles.listItem}>
            c.	Amihan Global Strategies (Amihan); and
          </Text>
          <Text style={styles.listItem}>
            d.	Talino Labs Pte Ltd (Talino Venture Labs). 
          </Text>
        </View>
      </View>
      <Text style={styles.paragraph}>
        Except as otherwise provided above, we do not share your personal information with any other third parties, unless necessary to the services we provide or when you specifically consent to the transfer or sharing of your personal information. In such a case, the transfer of your personal information will be done in accordance with applicable Privacy Laws. You will be notified of such transfer or sharing, and we will take appropriate safeguards to ensure the integrity and protection of the personal information being transferred. 
      </Text>
      <Text style={styles.paragraph}> 
        We will delete your personal data when it is no longer reasonably required for the above purposes or when you withdraw your consent (where applicable), provided that we are not legally required or otherwise permitted to continue to hold such data. We may retain your personal data for an additional period to the extent we deem it necessary to comply with regulatory compliance requirements or assert or defend legal claims during any relevant retention period. 
      </Text>
      <Text style={styles.subheading}>How We Secure And Protect Personal Information</Text>
      <Text style={styles.paragraph}>
        We take the necessary and appropriate organizational, physical and technical security measures against unauthorized or unlawful processing of your personal data and against accidental loss or destruction of, or damage to, your personal data. These measures are aligned with industry standards and are compliant with those laid down under relevant Privacy Laws.
      </Text>
      <Text style={styles.paragraph}>
        We also limit access to your personal information to the staff assigned to your matter or those personnel who require such data in the performance of their functions. 
      </Text>

      <Text style={styles.subheading}>Data Retention Period</Text>
      <Text style={styles.paragraph}>
        We will retain your personal data only for as long as it is necessary to fulfill the purposes for which it was collected or as required by law. In the absence of any legal retention requirements, your personal data will be securely disposed of after the expiration of the retention period, unless you request otherwise. We may also anonymize your data to ensure that it is no longer identifiable.
      </Text>

      <Text style={styles.subheading}>Rights as Data Subjects</Text>
      <Text style={styles.paragraph}>
        As data subjects, you have the following rights over your personal information: 
      </Text>
      <View style={styles.list}>
        <Text style={styles.listItem}>a. <Text style={styles.subTitle}>Right to Be Informed</Text> — You have the right to be informed whether your personal data shall be, are being, or have been processed, including whether automated decision-making and profiling will be involved in the processing.</Text>
        <Text style={styles.listItem}>b. <Text style={styles.subTitle}>Right to Object</Text> — You can object to processing of your personal data, including processing for direct marketing, automated processing, or profiling. </Text>
        <Text style={styles.listItem}>c. <Text style={styles.subTitle}>Right to Access</Text> — You have the right to reasonable access to the following, upon demand:
          <View style={styles.list}>
            <Text style={styles.listItem}>i.	contents of your personal data that was processed;</Text>
            <Text style={styles.listItem}>ii.	sources from which personal data was obtained;</Text>
            <Text style={styles.listItem}>iii.	names and addresses of recipients of the personal data;</Text>
            <Text style={styles.listItem}>iv.	manner by which such data was processed;</Text>
            <Text style={styles.listItem}>v.	reasons for the disclosure of the personal data to recipients, if any;</Text>
            <Text style={styles.listItem}>vi.	information on automated processes where your personal data will, or is likely to, be made as the sole basis for any decision that significantly affects or will affect you;</Text>
            <Text style={styles.listItem}>vii.	date when your personal data was last accessed and modified; and</Text>
            <Text style={styles.listItem}>viii.	the designation, name or identity, and address of the personal information controller.</Text>
          </View>
        </Text>
        <Text style={styles.listItem}>d. <Text style={styles.subTitle}>Right to Rectification</Text> — You have the right to dispute the inaccuracy or error in your personal data, unless the request is vexatious or otherwise unreasonable.</Text>
        <Text style={styles.listItem}>e. <Text style={styles.subTitle}>Right to Erasure or Blocking</Text> — You have the right to suspend, withdraw or order the blocking, removal or destruction of your personal information from our filing system, under the circumstances allowed under Data Privacy Laws.</Text>
        <Text style={styles.listItem}>f. <Text style={styles.subTitle}>Right to Damages</Text> — You have the right to be indemnified for any damages sustained due to such inaccurate, incomplete, outdated, false, unlawfully obtained or unauthorized use of your personal data. </Text>
        <Text style={styles.listItem}>g. <Text style={styles.subTitle}>Right to Portability</Text> — You have the right to obtain a copy of such your personal data in an electronic or structured format, which is commonly used and allows for your further use, in case your personal data was processed through electronic means and in a structured and commonly used format.</Text>
      </View>

      <Text style={styles.subheading}>Data Breach Notification</Text>
      <Text style={styles.paragraph}>
        In the unlikely event of a data breach that may pose a risk to your rights and freedoms, we will notify you and the relevant regulatory authorities (e.g., National Privacy Commission) within the timeframes required by applicable laws. We will also provide you with details of the breach, the steps we are taking to mitigate the impact, and advice on actions you may take to protect your data.
      </Text>

      <Text style={styles.subheading}>Third-Party Service Providers</Text>
      <Text style={styles.paragraph}>
        We may engage third-party service providers, such as cloud computing or IT support services, to process your personal data on our behalf. These service providers are contractually obligated to protect your personal information and will only process it as instructed by us and in compliance with applicable data privacy laws. We ensure that any third-party processors are subject to strict data protection obligations.
      </Text>

      <Text style={styles.subheading}>Cookies and Tracking Technologies</Text>
      <Text style={styles.paragraph}>
        Our website may use cookies and similar technologies to enhance your experience, understand usage patterns, and improve our services. You may adjust your browser settings to refuse cookies or be alerted when cookies are being sent. For more detailed information on our use of cookies, please refer to our Cookie Policy.
      </Text>
  
      <Text style={styles.subheading}>Requests, Concerns, or Notices</Text>
      <Text style={styles.paragraph}>
        Any requests, concerns, or notices relating to exercising your rights as indicated above may be sent in writing to the following: 
      </Text>
      <Text style={styles.contact}>• Email: gabb.ai@unawa.asia</Text>
      <Text style={styles.contact}>• Website: www.unawa.asia</Text>
      <Text style={styles.contact}>• Address: 10F 8 Rockwell, Hidalgo corner Plaza Drives, Rockwell Center, Makati City, Philippines</Text>
    </View>
  );
};

export default PrivacyPolicy;
