import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { DEVICE_SIZES, useDeviceSize } from "rn-responsive-styles";
import { DrawerContext } from '../../DrawerProvider';
import { DrawerComponentContext } from "../provider";
import DrawerItem from "./drawer-item";
import useStyles from './styles.css';

const DrawerList = () => {
  const styles = useStyles();
  const device_size = useDeviceSize();
  const { onMinimizeState } = useContext(DrawerComponentContext);
  const { drawerList, activeTab, onActiveTab, onSetActiveSecondScreen } = useContext(DrawerContext);
  const [hover, setHover] = useState(
    new Array<boolean>(drawerList.length).fill(false)
  );

  const handleHover = React.useCallback((eventName: string, idx: number) => {
    const isHover = eventName === "hoverIn";
    setHover((prevState) => ({
      ...prevState,
      [idx]: isHover,
    }));
  },[]);

  const onToggle = (itemName : string) => {
    if (DEVICE_SIZES.XS === device_size || DEVICE_SIZES.SM === device_size || DEVICE_SIZES.MD === device_size) {
      onMinimizeState();
    }
    onActiveTab(itemName);
    onSetActiveSecondScreen("");
  };
  
  return (
    <View style={styles.v_margintop}>
      {drawerList.map((label: string, index: number) => {
        const isHover = hover[index];
        const isActive = label === activeTab;

        return (
          <DrawerItem
            key={`tabs${index}`}
            isActive={isActive}
            isHover={isHover}
            item={label}
            onPress={() => onToggle(label)}
            onPressIn={() => handleHover("hoverIn", index)}
            onPressOut={() => handleHover("hoverOut", index)}
            onHoverIn={() => handleHover("hoverIn", index)}
            onHoverOut={() => handleHover("hoverOut", index)}
          />
        );
      })}
    </View>
  );
};

export default DrawerList;
