import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      padding: 24,
    },
    subheading: {
      fontSize: 18,
      fontWeight: 'bold',
      marginTop: 20,
      marginBottom: 10,
      color: colors.black5,
      lineHeight: 24,
      fontFamily: 'DMSans-Medium'
    },
    paragraph: {
      fontSize: 18,
      lineHeight: 24,
      marginBottom: 10,
      fontFamily: 'DMSans',
      color: colors.black5,
    },
    list: {
      marginLeft: 20,
    },
    listItem: {
      fontSize: 18,
      lineHeight: 24,
      marginBottom: 5,
    },
    contact: {
      fontSize: 18,
      lineHeight: 24,
      color: colors.black5,
      marginLeft: 20,
      marginBottom: 5,
    },
    subTitle: {
      fontWeight: 'bold',
    }
  },
  {
  }
);

export default useStyles;
