import React from 'react';
import Edit from '@assets/svg/ic_edit.svg';
import Text from '@assets/svg/ic_text.svg';
import Back from '@assets/svg/icon_back.svg';
import Next from '@assets/svg/icon_next.svg';
import Camera from '@assets/svg/icon_camera.svg';
import Document from '@assets/svg/icon_document.svg';
import AddFill from '@assets/svg/icon_add_fill.svg';
import Initial from '@assets/svg/ic_initial.svg';
import Designation from '@assets/svg/ic_designation.svg';
import Date from '@assets/svg/ic_date.svg';
import QRCode from '@assets/svg/ic_qr.svg';
import Pencil from '@assets/svg/ic_pencil.svg';
import Logo from '@assets/svg/ic_logo.svg';
import Users from '@assets/svg/ic_users.svg';
import AccountIcon from '@assets/svg/icon_account_icon.svg';
import SignHere from '@assets/svg/sign-here.svg';
import ArrowUpOutlined from '@assets/svg/arrow_up_outlined.svg';
import ArrowDownOutlined from '@assets/svg/arrow_down_outlined.svg';
import ZoomInlined from '@assets/svg/zoom_in_outlined.svg';
import ZoomOutlined from '@assets/svg/zoom_out_outlined.svg';
import Unawa from "./unawa.svg";
import UnawaLogo from "./unawa_logo.svg";
import UnawaProductBlack from "./UnawaProductBlack.svg";
import UnawaProduct from "./product_unawa.svg";
import NotarizeLogo from "./notarize_logo.svg";
import EmailSent from "./email_sent.svg";
import EmailSentPurple from "./emailsent-purple.svg";
import Search from "./ic-search.svg";
import Refresh from "./ic-refresh.svg";
import DocumentComplete from "./document-complete.svg";
import Delete from "./ic_trash.svg";
import Maya from "./ic_maya.svg";
import FaceSad from "./face-sad.svg";
import FaceSadV2 from "./face-sad-v2.svg";
import FaceSmileV2 from "./face-smile-v2.svg";
import FaceSmile from "./face-smile.svg";
import CardSuccess from "./card-success.svg"
import CardFailed from "./card-unsuccess.svg"
import PhoneCall from "./ic_phone_call.svg"
import DocumentDeleted from "./icon_document_deleted.svg"
import AccessDenied from "./icon-access-denied.svg"

import Icon from '@expo/vector-icons/MaterialIcons';
import { IconProps } from '@src/components/icon/interface';
import colors from '../colors';

const getSvgXML = (props: IconProps) => {
  const { name, width, height } = props;
  switch (name) {
    case 'Logo':
      return <QRCode width={width || 24} height={height || 24} {...props} />;
    case 'qr':
      return <Logo width={width || 24} height={height || 24} {...props} />;
    case 'pencil':
      return <Pencil width={width || 24} height={height || 24} {...props} />;
    case 'date':
      return <Date width={width || 24} height={height || 24} {...props} />;
    case 'designation':
      return <Designation width={width || 24} height={height || 24} {...props} />;
    case "UnawaProduct":
      return <UnawaProduct width={500} height={height || 20} {...props} />;
    case "UnawaProductBlack":
      return <UnawaProductBlack width={width || 160} height={height || 20} {...props} />;
    case "NotarizeLogo":
      return <NotarizeLogo width={width || 160} height={0} {...props} />;
    case 'initial':
      return <Initial width={width || 24} height={height || 24} {...props} />;
    case 'text':
      return <Text width={width || 24} height={height || 24} {...props} />;
    case 'edit':
      return (
        <Edit
          width={width || 24}
          height={height || 24}
          stroke={props.stroke || '#262E37'}
        />
      );
    case 'back':
      return <Back width={width || 12} height={height || 18} {...props} />;
    case 'next':
      return <Next width={width || 24} height={height || 24} {...props} />;
    case 'camera':
      return <Camera width={width || 24} height={height || 24} {...props} />;
    case 'document':
      return <Document width={width || 24} height={height || 24} {...props} />;
    case 'addFill':
      return <AddFill width={width || 24} height={height || 24} {...props} />;
    case 'users':
      return <Users width={width || 29} height={height || 29} {...props} />;
    case 'account_icon':
      return <AccountIcon {...props} />;
    case "SignHere":
      return <SignHere width={width || 147} height={height || 38} {...props} />;
    case "arrow_up_outlined":
      return <ArrowUpOutlined width={width || 48} height={height || 48} {...props} />;
    case "arrow_down_outlined":
      return <ArrowDownOutlined width={width || 48} height={height || 48} {...props} />;
    case "zoom_in_outlined":
      return <ZoomInlined width={width || 48} height={height || 48} {...props} />;
    case "zoom_out_outlined":
      return <ZoomOutlined width={width || 48} height={height || 48} {...props} />;
    case "Unawa":
      return <Unawa width={width || 270} height={height || 56} fill={props.fill || "white"} />;
    case "UnawaLogo":
      return <UnawaLogo width={width || 42} height={height || 49} fill={props.fill || "white"} />;
    case "email-sent":
      return <EmailSent width={width || 73} height={height || 75} {...props} />;
    case "emailsent-purple":
      return <EmailSentPurple width={width || 73} height={height || 75} {...props} />;
    case "search":
      return <Search width={width || 16} height={height || 17} {...props} />;
    case "refresh":
      return <Refresh width={width || 22} height={height || 22} {...props} />;
    case "document-complete":
      return <DocumentComplete width={width || 79} height={height || 80} {...props} />;
    case "face-smile":
      return <FaceSmile width={width || 80} height={height || 80} />;
    case "face-smile-v2":
      return <FaceSmileV2 width={width || 80} height={height || 80} />;
    case "face-sad":
      return <FaceSad width={width || 80} height={height || 80} {...props} />;
    case "face-sad-v2":
      return <FaceSadV2 width={width || 80} height={height || 80} {...props} />;
    case "maya":
      return <Maya width={width || 127} height={height || 99} />;
    case "Delete":
      return <Delete width={width || 11} height={height || 13} fill={props.fill || "black"} />;
    case "card-success":
      return <CardSuccess width={width || 125} height={height || 78} fill={props.fill || colors.purple4} />;
    case "card-failed":
      return <CardFailed width={width || 125} height={height || 78} fill={props.fill || colors.purple4} />;
    case "phone-call":
      return <PhoneCall width={width || 111} height={height || 98} fill={props.fill || colors.purple4} />;
    case "document-deleted":
      return <DocumentDeleted width={width || 128} height={height || 128} fill={props.fill || colors.purple4} />;
    case "access-denied":
      return <AccessDenied width={width || 120} height={height || 110} />;
    default:
      return <Icon name={props.name} color={props.color} size={props.size} />;
  }
};

export default getSvgXML;
