import React from "react";
import { useFetchPost } from "@src/utils/api/hooks";
import { useAuthService } from "@src/ducks/hooks";
import { View, Text } from "react-native";
import Verification from "./verification";
import * as API from "@src/utils/api";
import useStyles from "./styles.css";
import moment from "moment";
import _ from "lodash";
import { tabletSize } from "@src/utils/screensize-helper";
import Button from '@src/components/button/Button02';
import colors from "@assets/colors";

const UploadSignature = (props: any) => {
  const styles = useStyles();
  const { accessToken, onOtpSuccess } = useAuthService();
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");
  const { loading, data, runRequest } = useFetchPost();
  const [isLoading, setLoading] = React.useState(false);
  const [resendEnable, setResendEnable] = React.useState<boolean>(false);

  const resendOTP = React.useCallback(() => {
    runRequest("/user/generate-otp", { isTest: false });
      setResendEnable(false);
  });

  const validateOTP = async () => {
    try {
      setLoading(true);
      const result = await API.validateOTP({
        otp: value,
        sessionId: props.generated.sessionId,
        accessToken,
      });

      if(result.data.valid){
        onOtpSuccess()
      }else{
        setError("Invalid OTP Code");
      }

      setLoading(false);
    } catch (e: any) {
      const message = e.message || e.error || "Somthing went wrong";
      setError(message);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if(!_.isEmpty(error)){
      setError("");
    }
  },[value]);

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      props.setGenerateOTP({
        sessionId: data.otpExpiration,
      });
    }
  },[data]);

  return (
    <>
      <View style={styles.container}>
        <Text style={styles.t_title}>Verify Your Email</Text>
        <Text style={styles.t_subtitle}>
            Before signing in, we need to verify your email address. We’ll {"\n"} send you a One-Time Password (OTP) to the email provided. {"\n"} Please enter the OTP below to proceed with signing in.
        </Text>
        <Verification {...{value, error, setValue, generated: props.generated, setResendEnable}} />

        <View style={{flexDirection: "row", alignItems: "center", marginTop: 50}}>
          {resendEnable && <Button
              label="Resend OTP"
              onPress={resendOTP}
              style={styles.btn_resend}
              isLoading={loading || isLoading} 
              color={colors.colorPrimaryLight}
              labelStyle={styles.label_resend}
            />
          }
          <View style={styles.divider} />
          <Button
            disabled={loading}
            isLoading={loading || isLoading} 
            onPress={validateOTP}
            label="Submit OTP"
            style={styles.btn_submit}
            labelStyle={styles.labelStyle}
          />
        </View>
      </View>
    </>
  );
};

export default UploadSignature;
