import { Asset } from 'expo-asset';
import { ImageRequireSource } from 'react-native';

interface imageType {
  // Assets
  favicon: ImageRequireSource;
  
  // MANIFEST
  enotary_logo: ImageRequireSource;

  // AUTH
  bg_signin: ImageRequireSource;
  bg_signup: ImageRequireSource;
  bg_signup_image: ImageRequireSource;
  google: ImageRequireSource;
  enotary: ImageRequireSource;
  enotary_horizontal: ImageRequireSource;
  enotary_horizontal_color: ImageRequireSource;
  check: ImageRequireSource;
  error: ImageRequireSource;
  scan_frame: ImageRequireSource;

  // HEADER
  ic_back: ImageRequireSource;
  
  // SIGNATURE
  highlight: ImageRequireSource;
  ic_check: ImageRequireSource;
  ic_delete: ImageRequireSource;
  ic_upload: ImageRequireSource;
  "quick-signing": ImageRequireSource;

  // DASHBOARD
  dashboard: ImageRequireSource;
  transactions: ImageRequireSource;
  inbox: ImageRequireSource;
  digital_seal_stamp: ImageRequireSource;
  digital_notarial_book: ImageRequireSource;
  partner_notaries: ImageRequireSource;
  signature: ImageRequireSource;
  account: ImageRequireSource;
  faq: ImageRequireSource;
  support: ImageRequireSource;
  ic_liverequest: ImageRequireSource;
  ic_schedule: ImageRequireSource;
  ic_applications: ImageRequireSource;
  ic_folders: ImageRequireSource;
  ic_bell: ImageRequireSource;

  bg_mask: ImageRequireSource;
  v2_bg_mask: ImageRequireSource;
  v2_enotary: ImageRequireSource;
  v2_unawa_logo: ImageRequireSource;
  logo: ImageRequireSource;
  placeholder_profile: ImageRequireSource;
  camera_icon: ImageRequireSource;
  document_icon: ImageRequireSource;
  icon_download: ImageRequireSource;
  icon_share: ImageRequireSource;
  icon_zoomin: ImageRequireSource;
  icon_zoomout: ImageRequireSource;
  icon_export: ImageRequireSource;
  icon_close: ImageRequireSource;
  icon_pen: ImageRequireSource;
  icon_gallery: ImageRequireSource;
  icon_upload: ImageRequireSource;
  icon_text: ImageRequireSource;
  icon_reload: ImageRequireSource;
  icon_upload_success: ImageRequireSource;
  ic_unawa: ImageRequireSource;

  // LIVEREQUEST
  ic_document: ImageRequireSource;
  ic_calendar: ImageRequireSource;
  ic_eye: ImageRequireSource;
  ic_approved: ImageRequireSource;
  ic_reject: ImageRequireSource;
  ic_docs: ImageRequireSource;
  ic_send_message: ImageRequireSource;
  ic_edit_black: ImageRequireSource;

  // LIVE SESSION
  face_scan_frame: ImageRequireSource;
  live_info: ImageRequireSource;
  live_show_everyone: ImageRequireSource;
  live_chat: ImageRequireSource;
  live_send_message: ImageRequireSource;
  live_session_concluded: ImageRequireSource;

  //edit profile
  ic_edit: ImageRequireSource;
  ic_save: ImageRequireSource;
}

const images: imageType = {
  // ASSETS
  favicon: require('../favicon.png'),

  // MANIFEST
  enotary_logo: require('./manifest/logo.png'),

  // AUTH FOLDER
  bg_signin: require('./auth/bg_signin.png'),
  google: require('./auth/google.png'),
  enotary: require('./auth/enotary.png'),
  bg_mask: require('./auth/bg_mask.png'),
  v2_bg_mask: require('./auth/new_bg_mask.png'),
  v2_enotary: require('./auth/new_enotary.png'),
  v2_unawa_logo: require('./auth/new_unawa_logo.png'),
  enotary_horizontal: require('./auth/enotary_horizontal.png'),
  enotary_horizontal_color: require('./auth/enotary_horizontal_color.png'),
  check: require('./auth/check.png'),
  error: require('./auth/error.png'),
  scan_frame: require('./auth/scan_frame.png'),

  // HEADER
  ic_back: require("./header/ic_back.png"),

  // SIGNATURE
  highlight: require('./signature/highlight.png'),
  ic_check: require('./signature/ic_check.png'),
  ic_delete: require('./signature/ic_delete.png'),
  ic_upload: require("./signature/ic_upload.png"),
  "quick-signing": require("./signature/quick-signing.png"),

  // DASHBOARD
  dashboard: require('./dashboard/dashboard_icon.png'),
  transactions: require('./dashboard/transactions_icon.png'),
  inbox: require('./dashboard/inbox_icon.png'),
  digital_seal_stamp: require('./dashboard/digital_seal_stamp_icon.png'),
  digital_notarial_book: require('./dashboard/digital_notarial_book_icon.png'),
  partner_notaries: require('./dashboard/partner-notaries.png'),
  signature: require('./dashboard/signature_icon.png'),
  account: require('./dashboard/account_icon.png'),
  faq: require('./dashboard/faq_icon.png'),
  support: require('./dashboard/support_icon.png'),
  ic_schedule: require('./dashboard/ic_schedule.png'),
  ic_liverequest: require('./dashboard/ic_liverequest.png'),
  ic_applications: require('./dashboard/ic_applications.png'),
  ic_folders: require('./dashboard/ic_folders.png'),
  ic_bell: require('./dashboard/ic_bell.png'),

  bg_signup: require('./auth/bg_signin.png'),
  bg_signup_image: require('./bg_signup_image.png'),
  logo: require('./logo1.png'),
  placeholder_profile: require('./placeholder_profile.png'),
  camera_icon: require('./camera_icon.png'),
  document_icon: require('./document_icon.png'),
  icon_download: require('./icon_download.png'),
  icon_share: require('./icon_share.png'),
  icon_zoomin: require('./icon_zoomin.png'),
  icon_zoomout: require('./icon_zoomout.png'),
  icon_export: require('./icon_export.png'),
  icon_close: require('./icon_close.png'),
  icon_pen: require('./icon_pen.png'),
  icon_upload: require('./icon_upload.png'),
  icon_gallery: require('./icon_gallery.png'),
  icon_text: require('./icon_text.png'),
  icon_reload: require('./icon_reload.png'),
  icon_upload_success: require('./icon_upload_success.png'),
  ic_unawa: require('./ic_unawa.png'),

  // LIVEREQUEST
  ic_document: require('./liverequest/ic_document.png'),
  ic_calendar: require('./liverequest/ic_calendar.png'),
  ic_eye: require('./liverequest/ic_eye.png'),
  ic_approved: require('./liverequest/ic_approved.png'),
  ic_reject: require('./liverequest/ic_reject.png'),
  ic_docs: require('./liverequest/ic_docs.png'),
  ic_send_message: require('./liverequest/ic_send_message.png'),
  ic_edit_black: require("./ic_edit_black.png"),

  // LIVE SESSION
  face_scan_frame: require('./live-session/face_scan_frame.png'),
  live_info: require('./live-session/live-session-info.png'),
  live_show_everyone: require('./live-session/live-session-show-everyone.png'),
  live_chat: require('./live-session/live-session-chat.png'),
  live_send_message: require('./live-session/live-session-send-message.png'),
  live_session_concluded: require('./live-session/live-session-concluded.png'),

  //edit profile
  ic_edit: require("./ic_edit.png"),
  ic_save: require("./ic_save.png"),

};

// image preloading
export const imageAssets = Object.keys(images).map((key) =>
  Asset.fromModule(images[key as keyof imageType]).downloadAsync()
);
export default images;
