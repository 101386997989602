import colors from '@assets/colors';
import Icon from '@expo/vector-icons/MaterialIcons';
import { useNavigation } from '@react-navigation/native';
import { Button01 } from '@src/components/button';
import { TextInput02 } from '@src/components/textinput';
import { Tooltip01 } from '@src/components/tooltip';
import { useSignupService } from '@src/ducks/hooks';
import { SignupInput } from '@src/ducks/types';
import { useInputHelper } from '@src/utils/inputs-helper';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import Validator from 'validator';
import { SignupContext } from './SignupProvider';
import useStyles from './styles.css';
import Terms from './terms';
import UploadMask from './upload-mask';
import DatePicker from '@src/components/date-picker';

const Notary = () => {
  const initialInputState = {
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    citizenship: '',
    email: '',
    mobile: '',
    phoneNumber: '',
    residentialAddress: '',
    placeOfBusiness: '',
    appointmentNo: '',
    rollNo: '',
    ptrNo: '',
    ptrNoDate: '',
    ibpNo: '',
    ibpNoDate: '',
    tinNo: '',
    mcleNo: '',
    password: '',
    confirmpassword: '',
    professionalTaxReceipt: {},
    goodMoralCertificate: {},
    passportPhoto: {},
    ibpId: {},
    role: 'notary',
    // province: '',
    // country: '',
    // city: '',
    // adminMatterNo: '',
    // validId: {},
  } as SignupInput;

  const styles = useStyles();
  const navigation = useNavigation<any>();
  const [terms, setTerms] = useState<boolean>(false);
  const { updateScreen } = useContext(SignupContext);
  const { data, failed, isLoading, onSignup } = useSignupService();
  const { state, onDispatch } = useInputHelper(initialInputState);
  const [error, setError] = useState<SignupInput>({} as SignupInput);

  const onChangeText = (type: string) => (value: string) => {
    onDispatch(type)(value);
    setError({} as SignupInput);
  };

  const handleSubmit = () => {
    const newErr: any = {} as SignupInput;

    const requiredFields = [
      'firstName',
      // 'middleName',
      'lastName',
      'dateOfBirth',
      'citizenship',
      'email',
      'mobile',
      // 'phoneNumber',
      'residentialAddress',
      'placeOfBusiness',
      'appointmentNo',
      'rollNo',
      'ptrNo',
      'ptrNoDate',
      // 'ibpNo',
      'ibpNoDate',
      'ibpId',
      'tinNo',
      'mcleNo',
      'password',
      'confirmpassword',
      'professionalTaxReceipt',
      'passportPhoto',
      'goodMoralCertificate',
    ];

    requiredFields.forEach((field) => {
      if (field === 'ibpNoDate' || field === 'ibpId') {
        // Only check ibpNoDate and ibpId if ibpNo is not empty
        if (!_.isEmpty(state['ibpNo']) && _.isEmpty(state[field])) {
          newErr[field] = 'This field is required';
        }
      } else if (_.isEmpty(state[field])) {
        newErr[field] = 'This field is required';
      }
    });

    if (state.email && !Validator.isEmail(state.email)) {
      newErr.email = 'Invalid email format';
    }

    if (state.dateOfBirth === 'Invalid Date') {
      newErr.dateOfBirth = 'Invalid date';
    }
    
    if (state.ptrNoDate === 'Invalid Date') {
      newErr.ptrNoDate = 'Invalid date';
    }

    if (state.ibpNoDate === 'Invalid Date') {
      newErr.ibpNoDate = 'Invalid date';
    }

    if (state.password !== state.confirmpassword) {
      newErr.confirmpassword = 'Passwords do not match';
    }

    if (state.password.length <= 5) {
      newErr.password = 'Password must be at least six characters';
    }

    setError(newErr);
    if (_.isEmpty(newErr)) {
      // state.dateOfBirth = +new Date(state.dateOfBirth)
      onSignup(state);
    }
  };

  const handleEmailBlur = () => {
    const { email } = state;

    setError((prevErrors) => ({
      ...prevErrors,
      email:
        email && !Validator.isEmail(email)
          ? 'Invalid email format'
          : error.email,
    }));
  };

  const handleMatchPassword = () => {
    const { password, confirmpassword } = state;
    if (password !== confirmpassword) {
      setError((prevErrors) => ({
        ...prevErrors,
        confirmpassword: 'Passwords do not match',
      }));
    }
  };

  const handlePasswordLength = () => {
    if (state.password.length <= 5) {
      setError((prevErrors) => ({
        ...prevErrors,
        password: 'Password must be at least six characters',
      }));
    }
  };

  useEffect(() => {
    if (!_.isEmpty(data)) {
      updateScreen('emailsent-notary');
    }
  }, [data]);

  useEffect(() => {
    if (!_.isEmpty(failed)) {
      setError({ message: failed.message } as SignupInput);
    }
  }, [failed]);

  useEffect(() => {
    if (!_.isEmpty(error)) {
      setError({} as SignupInput);
    }
  }, [state]);

  return (
    <View style={styles.v_signup}>
      {/* <ScrollView> */}
      {/* <BackButton /> */}
      <View style={styles.signInCenter}>
        <Text style={styles.txtNote1}>Create an account</Text>
        <Text style={styles.t_publicnotary}>
          You are signing up as a Notary Public
        </Text>
        <Text style={styles.txtNote2}>Basic Information</Text>
      </View>
      <View>
        <View style={styles.v_wrap_row}>
          <TextInput02
            label="First Name"
            value={state.firstName}
            error={error.firstName}
            errorStyle={styles.errorStyles}
            style={styles.v_flex1}
            placeholder="Your First Name"
            onChangeText={onChangeText('firstName')}
            borderStyle={styles.inputBorderStyle}
          />
          <View style={styles.v_divider} />
          <TextInput02
            label="Middle Name (optional)"
            style={styles.v_flex1}
            value={state.middleName}
            error={error.middleName}
            placeholder="Your Middle Name"
            onChangeText={onChangeText('middleName')}
            borderStyle={styles.inputBorderStyle}
          />
          <View style={styles.v_divider} />
          <TextInput02
            label="Last Name"
            style={styles.v_flex1}
            value={state.lastName}
            error={error.lastName}
            errorStyle={styles.errorStyles}
            placeholder="Your Last Name"
            onChangeText={onChangeText('lastName')}
            borderStyle={styles.inputBorderStyle}
          />
        </View>
        <View style={styles.v_wrap_row}>
          {/* <TextInput02
            label="Date of Birth"
            value={state.dateOfBirth}
            error={error.dateOfBirth}
            errorStyle={styles.errorStyles}
            style={styles.v_flex1}
            placeholder="06/24/2024"
            onChangeText={onChangeText('dateOfBirth')}
            borderStyle={styles.inputBorderStyle}
          /> */}
          <DatePicker
            disablePast
            inputSx={styles.date_picker_input_sx}
            value={state.dateOfBirth ? new Date(state.dateOfBirth) : null} 
            label="Date of Birth"
            placeholder="MM/DD/YYYY"
            inputStyle={styles.date_picker_input_style}
            style={styles.date_picker_style}
            errorStyle={styles.date_picker_error_style}
            disableOpenPicker={true}
            isFullWidth={true}
            error={error.dateOfBirth}
            onChange={(date: Date | null) => {
              if (date) {
                const formattedDate = date.toLocaleDateString('en-US');
                onChangeText('dateOfBirth')(formattedDate);
              } else {
                onChangeText('dateOfBirth')('');
              }
            }}
          />
          <View style={styles.v_divider} />
          <TextInput02
            label="Citizenship"
            style={styles.v_flex1}
            placeholder="Enter your citizenship"
            value={state.citizenship}
            error={error.citizenship}
            errorStyle={styles.errorStyles}
            onChangeText={onChangeText('citizenship')}
            borderStyle={styles.inputBorderStyle}
          />
          <View style={styles.v_divider} />
          <TextInput02
            label="Email Address"
            style={styles.v_flex1}
            placeholder="Enter your email address"
            value={state.email}
            error={error.email}
            errorStyle={styles.errorStyles}
            onChangeText={onChangeText('email')}
            borderStyle={styles.inputBorderStyle}
          />
        </View>

        <View style={[styles.v_wrap_row]}>
          <TextInput02
            label="Mobile Number"
            style={styles.v_flex1}
            placeholder="Enter your mobile number"
            value={state.mobile}
            error={error.mobile}
            errorStyle={styles.errorStyles}
            onChangeText={onChangeText('mobile')}
            borderStyle={styles.inputBorderStyle}
          />
          <View style={styles.v_divider} />
          <TextInput02
            label="Telephone Number (optional)"
            style={styles.v_flex1}
            value={state.phoneNumber}
            error={error.phoneNumber}
            errorStyle={styles.errorStyles}
            onChangeText={onChangeText('phoneNumber')}
            placeholder="Enter your telephone number"
            borderStyle={styles.inputBorderStyle}
          />
          <View style={styles.v_divider1} />
        </View>

        <View style={[styles.v_wrap_row, { width: '100%' }]}>
          <TextInput02
            label="Residential Address"
            style={styles.v_flex1}
            placeholder="Enter your address"
            value={state.residentialAddress}
            error={error.residentialAddress}
            errorStyle={styles.errorStyles}
            onChangeText={onChangeText('residentialAddress')}
            borderStyle={styles.inputBorderStyle}
          />
        </View>
        <View style={[styles.v_wrap_row, { width: '100%' }]}>
          <TextInput02
            label="Regular Place of Business"
            style={styles.v_flex1}
            placeholder="Enter your address"
            value={state.placeOfBusiness}
            error={error.placeOfBusiness}
            errorStyle={styles.errorStyles}
            onChangeText={onChangeText('placeOfBusiness')}
            borderStyle={styles.inputBorderStyle}
          />
        </View>
        <View style={styles.v_wrap_row}>
          <TextInput02
            label="Appointment Number"
            style={styles.v_flex1}
            placeholder="Enter your Appointment No."
            value={state.appointmentNo}
            error={error.appointmentNo}
            errorStyle={styles.errorStyles}
            onChangeText={onChangeText('appointmentNo')}
            borderStyle={styles.inputBorderStyle}
          />
          <View style={styles.v_divider} />
          <TextInput02
            label="Roll No."
            style={styles.v_flex1}
            placeholder="Enter your roll no."
            value={state.rollNo}
            error={error.rollNo}
            errorStyle={styles.errorStyles}
            onChangeText={onChangeText('rollNo')}
            borderStyle={styles.inputBorderStyle}
          />
          <View style={styles.v_divider} />
          <TextInput02
            label="PTR Number"
            style={styles.v_flex1}
            placeholder="Enter your PTR no."
            value={state.ptrNo}
            error={error.ptrNo}
            errorStyle={styles.errorStyles}
            onChangeText={onChangeText('ptrNo')}
            borderStyle={styles.inputBorderStyle}
          />
          <View style={styles.v_divider} />
          {/* <TextInput02
            label="PTR No. Date"
            value={state.ptrNoDate}
            error={error.ptrNoDate}
            errorStyle={styles.errorStyles}
            style={styles.v_flex1}
            placeholder="06/24/2024"
            onChangeText={onChangeText('ptrNoDate')}
            borderStyle={styles.inputBorderStyle}
          /> */}
          <DatePicker
            disablePast
            inputSx={styles.date_picker_input_sx}
            value={state.dateOfBirth ? new Date(state.ptrNoDate) : null} 
            label="PTR No. Date"
            placeholder="MM/DD/YYYY"
            inputStyle={styles.date_picker_input_style}
            style={styles.date_picker_style}
            errorStyle={styles.date_picker_error_style}
            disableOpenPicker={true}
            isFullWidth={true}
            error={error.ptrNoDate}
            onChange={(date: Date | null) => {
              if (date) {
                const formattedDate = date.toLocaleDateString('en-US');
                onChangeText('ptrNoDate')(formattedDate);
              } else {
                onChangeText('ptrNoDate')('');
              }
            }}
          />
        </View>
        <View style={[styles.v_wrap_row]}>
          <TextInput02
            label="Life IBP Membership Number"
            style={styles.v_flex1}
            placeholder="Enter your IBP membership no."
            value={state.ibpNo}
            error={error.ibpNo}
            errorStyle={styles.errorStyles}
            onChangeText={onChangeText('ibpNo')}
            borderStyle={styles.inputBorderStyle}
          />
          <View style={styles.v_divider} />
          {/* <TextInput02
            label="IBP No. Date"
            value={state.ibpNoDate}
            error={error.ibpNoDate}
            errorStyle={styles.errorStyles}
            style={styles.v_flex1}
            placeholder="06/24/2024"
            onChangeText={onChangeText('ibpNoDate')}
            borderStyle={styles.inputBorderStyle}
          /> */}
          <DatePicker
            disablePast
            inputSx={styles.date_picker_input_sx}
            value={state.dateOfBirth ? new Date(state.ibpNoDate) : null} 
            label="IBP No. Date"
            placeholder="MM/DD/YYYY"
            disabled={_.isEmpty(state.ibpNo)}
            inputStyle={styles.date_picker_input_style}
            style={styles.date_picker_style}
            errorStyle={styles.date_picker_error_style}
            disableOpenPicker={true}
            isFullWidth={true}
            error={error.ibpNoDate}
            onChange={(date: Date | null) => {
              if (date) {
                const formattedDate = date.toLocaleDateString('en-US');
                onChangeText('ibpNoDate')(formattedDate);
              } else {
                onChangeText('ibpNoDate')('');
              }
            }}
          />
          <View style={styles.v_divider} />
          <TextInput02
            label="TIN Number"
            style={styles.v_flex1}
            value={state.tinNo}
            error={error.tinNo}
            errorStyle={styles.errorStyles}
            onChangeText={onChangeText('tinNo')}
            placeholder="Enter your TIN no."
            borderStyle={styles.inputBorderStyle}
          />
          <View style={styles.v_divider} />
          <TextInput02
            label="MCLE Compliance Number"
            style={styles.v_flex1}
            placeholder="Enter your MCLE compliance no."
            value={state.mcleNo}
            error={error.mcleNo}
            errorStyle={styles.errorStyles}
            onChangeText={onChangeText('mcleNo')}
            borderStyle={styles.inputBorderStyle}
          />
          {/* <TextInput02
            label="Admin Matter Number"
            style={styles.v_flex1}
            placeholder="Enter your Admin matter no."
            value={state.adminMatterNo}
            error={error.adminMatterNo}
            errorStyle={styles.errorStyles}
            onChangeText={onChangeText('adminMatterNo')}
            borderStyle={styles.inputBorderStyle}
          />
          <View style={styles.v_divider1} /> */}
        </View>
        <View style={styles.v_wrap_row}>
          <TextInput02
            hasPassword
            label="Password"
            style={styles.v_flex1}
            value={state.password}
            error={error.password}
            errorStyle={styles.errorStyles}
            placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
            onChangeText={onChangeText('password')}
            onBlur={handlePasswordLength}
            borderStyle={styles.inputBorderStyle}
          />
          <View style={styles.v_divider} />
          <TextInput02
            hasPassword
            style={styles.v_flex1}
            label="Confirm Password"
            value={state.confirmpassword}
            error={error.confirmpassword}
            errorStyle={styles.errorStyles}
            placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
            onChangeText={onChangeText('confirmpassword')}
            onBlur={handleMatchPassword}
            borderStyle={styles.inputBorderStyle}
          />
        </View>
        <View>
          <Text style={styles.uploadDocText}>Upload Documents</Text>
        </View>
        <View style={styles.v_wrap_row}>
          <View style={styles.v_flex1}>
            <View style={{ flexDirection: 'row' }}>
              <UploadMask
                label="Upload Professional Tax Receipt"
                value={state.professionalTaxReceipt.name}
                error={Boolean(error.professionalTaxReceipt)}
                onSource={onDispatch('professionalTaxReceipt')}
              />
              <Tooltip01
                label={`Please upload a scanned copy \n of your professional tax receipt`}
                style={{ height: 80, top: '-50%' }}
              >
                <Icon
                  name="help-outline"
                  size={16}
                  color={colors.gray7}
                  style={{ marginTop: 22, paddingLeft: 10 }}
                />
              </Tooltip01>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <UploadMask
                label="Upload Passport-size Photo"
                value={state.passportPhoto.name}
                error={Boolean(error.passportPhoto)}
                onSource={onDispatch('passportPhoto')}
              />
              <Tooltip01
                label={`Colored photograph with light \n background taken within 30 \n days of application. Photo \n should not be retouched.`}
                style={{ top: '-100%' }}
              >
                <Icon
                  name="help-outline"
                  size={16}
                  color={colors.gray7}
                  style={{ marginTop: 22, paddingLeft: 10, opacity: 1 }}
                />
              </Tooltip01>
            </View>
          </View>
          <View style={styles.v_divider} />
          <View style={styles.v_flex2}>
            <View style={{ flexDirection: 'row' }}>
              <UploadMask
                label="Upload Certification of Good Moral Character"
                value={state.goodMoralCertificate.name}
                error={Boolean(error.goodMoralCertificate)}
                onSource={onDispatch('goodMoralCertificate')}
              />
              <Tooltip01
                label={`An electronic/scanned copy of \n the certification of good moral \n by at lease two (2) executive \n officer of the IBP chapter`}
                style={{ top: '-50%' }}
              >
                <Icon
                  name="help-outline"
                  size={16}
                  color={colors.gray7}
                  style={{ marginTop: 22, paddingLeft: 10, opacity: 1 }}
                />
              </Tooltip01>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <UploadMask
                label="Upload IBP ID"
                disabled={_.isEmpty(state.ibpNo)}
                value={state.ibpId.name}
                error={Boolean(error.ibpId)}
                onSource={onDispatch('ibpId')}
              />
              <Tooltip01
                label={`Please upload a scanned copy \n of your IBP ID`}
                style={{ height: 80, top: '-50%' }}
              >
                <Icon
                  name="help-outline"
                  size={16}
                  color={colors.gray7}
                  style={{ marginTop: 22, paddingLeft: 10, opacity: 1 }}
                />
              </Tooltip01>
            </View>
          </View>
        </View>
        <Terms isCheck={terms} onCheck={() => setTerms(!terms)} />
        <Button01
          label="Sign Up"
          color={colors.white}
          isLoading={isLoading}
          onPress={handleSubmit}
          style={styles.btn_signup}
          labelStyle={styles.lbl_signup}
          disabled={!terms}
        />
        {!_.isEmpty(error.message) && (
          <Text style={styles.t_error_message}>{error.message}</Text>
        )}
        <Text style={styles.t_signup}>
          Already have an account?
          <Text
            onPress={() => navigation.navigate('Signin')}
            style={styles.t_terms2}
          >
            {' '}
            Login
          </Text>
        </Text>
      </View>
      <View style={styles.v_height} />
      {/* </ScrollView> */}
    </View>
  );
};

export default Notary;
