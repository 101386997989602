/* eslint-disable no-useless-escape */
import { PDFDocument, PageSizes } from 'pdf-lib';
import _ from 'lodash';
import { nanoid } from '@reduxjs/toolkit';
import moment from 'moment';

export const dashboardDocuments = (array: any[]) => {
  return array.map((item: any) => {
    const auditTrail = item?.workflow?.auditTrail ?? [];
    const recent = auditTrail[auditTrail.length - 1];
    item.recentAction = recent?.details;
    item.status = item?.workflow?.status ?? '';
    return item;
  });
};

// DESCEND AUDITTRAIL
export const auditTrailDescending = (array: any[]) => {
  return _.chain(array).cloneDeep().reverse().value();
};

// IMAGE TO PDF
const convertImageToPDF = async (file: any) => {
  const pdfDoc = await PDFDocument.create();

  let image;
  if (/jpe?g/i.test(file.mimeType)) image = await pdfDoc.embedJpg(file.uri);
  else if (/png/i.test(file.mimeType)) image = await pdfDoc.embedPng(file.uri);
  else throw Error('Please choose a JPEG or PNG file to proceed');

  const page = pdfDoc.addPage(PageSizes.Letter);
  const { width, height } = page.getSize(); // Get the width & height of the page

  const scaled = image.scaleToFit(width, height);

  page.drawImage(image, {
    width: scaled.width,
    height: scaled.height,
    x: (width - scaled.width) / 2,
    y: (height - scaled.height) / 2,
  });

  const b64Chunk = await pdfDoc.saveAsBase64();
  return 'data:application/pdf;base64,' + b64Chunk;
};

// CHOOSE FILE TO READABLE FILE
export const chooseFiles = (item: any) =>
  new Promise((resolve, reject) => {
    if (item.mimeType !== 'application/pdf') {
      convertImageToPDF(item)
        .then((res: any) => {
          resolve({
            size: item.size,
            file: item.file,
            name: item.name.replace(/\.[^\/.]+$/, ''),
            mimeType: 'application/pdf',
            lastModified: item.lastModified,
            uri: res,
          });
        })
        .catch(reject);
    } else {
      resolve({
        size: item.size,
        name: item.name.replace(/\.[^\/.]+$/, ''),
        file: item.file,
        mimeType: item.mimeType,
        lastModified: item.lastModified,
        uri: item.uri,
      });
    }
  });

// FILE TO READABLE FILE
export const dragFiles = (item: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(item);
    reader.onload = async (e: any) => {
      const params = {
        size: item.size,
        name: item.name.replace(/\.[^\/.]+$/, ''),
        file: item,
        mimeType: item.type,
        lastModified: item.lastModified,
        lastModifiedDate: item.lastModifiedDate,
        uri: e.target.result,
      };

      if (params.mimeType !== 'application/pdf') {
        const result = await convertImageToPDF(params);
        params.uri = result;
        params.mimeType = 'application/pdf';
      }

      resolve(params);
    };
    reader.onerror = reject;
  });

export const convertFileListToArray = (fileList: any) => {
  const fileArray = [];

  for (const key in fileList) {
    if (fileList.hasOwnProperty(key)) {
      const file = fileList[key];
      fileArray.push(file);
    }
  }

  return fileArray;
};

// CONVERT ARRAY INTO PAGINATION
export const paginateArray = (array: any[], itemsPerPage: number) => {
  const pages = [];
  for (let i = 0; i < array.length; i += itemsPerPage) {
    pages.push(array.slice(i, i + itemsPerPage));
  }
  return pages;
};

// GENERATE NOTARY PUBLIC WITNESS DEtAILS
export const generateWitnessDetails = (config?: any) => {
  return {
    id: nanoid(),
    trackId: "notary",
    type: 'doc-stamp',
    "config": {
      "fill": "#000000",
      x: config.width * 0.057,
      // y: 540,
      "y": config.height - (config.height * 0.38),
      "width": 130,
      "height": 14,
      "rotation": 0,
      "stroke": "#000000",
      "strokeWidth": "1"
    },
    "nameConfig": {
      x: config.width * 0.057,
      "y": config.height - (config.height * 0.38),
      text: `WITNESS MY HAND AND SEAL this ${moment(Date.now()).format('MMMM DD, YYYY')} at Bacolod City, Philippines.`,
      "fontSize": 10,
      "width": 130,
      "fontFamily": "DMSans",
      "fill": "#000000"
    }
  }
}

// GENERATE NOTARY PUBLIC DATA
export const generateNotaryPublicDetails = (config: any) => {
  const docPageDetails = [
    "NOTARY PUBLIC",
    `Doc No. ${config.docNo}`,
    `Page No. ${config.pageNo}`,
    `Book No. ${config.bookNo}`,
    `Series of ${config.year}`,
  ];

  return {
    id: nanoid(),
    trackId: "notary",
    "type": "signature-notary-public",
    "config": {
      "fill": "#000000",
      "x": config.width * 0.057,
      "y": config.height - (config.height * 0.222),
      "width": 130,
      "height": 14,
      "rotation": 0,
      "fontSize": 10,
      "stroke": "#000000",
      "strokeWidth": "1"
    },
    "textConfig": {
      "id": "notary",
      "text": "Notarize",
      "fontSize": 10,
      "fontFamily": "DMSans",
      "fill": "#FFAAAA",
      "x": 62,
      "y": 640,
      "width": 130
    },
    "docPageDetails": docPageDetails
  }
}

// GENERATE NOTARY PUBLIC ATTORNEY DATA
export const generateAttorneyDetails = (config: any, attorneyDetails: any) => {
  const notaryDetails = attorneyDetails || [
    "ATTY. JUAN DELA CRUZ",
    "Notary Public for Q.C./Until Dec 31, 2024",
    "Roll No. 00123",
    "PTR No. 0000000/Jan 03, 2024/Q.C.",
    "IBP No. 100000, Jan 3, 2024",
    "MCLE COMP. NO. VII-0000000",
    "Adm. Matter No. NP- 062(2023-2024)",
    "10th St. East Fairview Quezon City",
    "TIN No. 138-5000-000-000"
  ];

  return {
    id: nanoid(),
    trackId: "notary",
    "type": "signature-atty-details",
    "config": {
      "fill": "#000000",
      "x": (config.width / 2) - (150 / 2),
      "y": config.height - (config.height * 0.28),
      "width": 150,
      "height": 58,
      "rotation": 0,
    },
    "textConfig": {
      "text": "SIGNATURE WILL BE PLACED HERE",
      "fontSize": 6,
      "fill": "#000000",
    },
    "nameConfig": {
      "fontSize": 10,
      "fill": "#000000",
    },
    "notaryDetails": notaryDetails
  }
}

// GENERATE SEAL STAMP
export const generateSealStamp = (config: any) => {
  const StampSize = config.width * 0.2042;

  return {
    id: nanoid(),
    trackId: "notary",
    "type": "signature-notary-stamp",
    "config": {
      "fill": "#FF9F00",
      "x": config.width - (StampSize + (config.width * 0.07)),
      "y": config.height - (config.height * 0.222),
      "width": StampSize,
      "height": StampSize,
      "maskHeight": 65,
      "rotation": 0,
      "stroke": "#FF9F00",
      "strokeWidth": "1"
    },
    "textConfig": {
      "id": "5ZXU8sdGCO8IlQ6nCPGyi",
      "text": "Notarize",
      "fontSize": 11,
      "fontFamily": "DMSans",
      "fill": "#FF9F00",
      "x": 399, // 612 - 157 - 56
      "y": 652,
      "width": 130
    },
  }
}