import { getStateFromPath } from '@react-navigation/native';
import { useAppDispatch, useAppSelector } from '@src/ducks/ducksHook';
import { useDashboardService, useSignsecureService } from '@src/ducks/hooks';
import { selectAuthLoggedIn } from '@src/ducks/slices/auth.slice';
import { dashboardActions } from '@src/ducks/slices/dashboard.slice';
import * as Linking from 'expo-linking';
import qs from 'qs';

const prefix = Linking.createURL('/');
const config: any = {
  screens: {
    Signin: 'signin',
    SignInWithFaceID: 'signin-face-id',
    SignInBack: 'hello-again',
    Signup: 'signup',
    Forgot: 'forgot',
    ResetPassword: 'reset-password/:token',
    'verification-check': 'verification-check',
    'verify-success': 'verify-success',
    'privacy-policy': 'privacy-policy',
    'terms-condition': 'terms-condition',
    Home: 'home',
    LiveRequest: 'live-request',
    VideoSession: 'live-request/video-session',
  },
};
const linking: any = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  const { viewDocumentRequest } = useSignsecureService();
  const  { onActiveDrawerChange } = useDashboardService();

  return {
    prefixes: [prefix],
    config,
    async getInitialURL() {
      return await Linking.getInitialURL();
    },
    subscribe(listener: any) {
      const onReceiveURL = ({ url }: { url: string }) => listener(url);
      const subscription = Linking.addEventListener('url', onReceiveURL);

      return () => subscription.remove();
    },

    getStateFromPath: (path: string) => {
      if (path.includes('verify-failed')) {
        return {
          routes: [{ name: 'SignIn' }, { name: 'verify-failed' }],
        };
      } else if (path.includes('live-request/video-session')) {
        return {
          routes: [{ name: 'Home' }, { name: 'VideoSession' }],
        };
      } else if (path.includes("/contactless/payment-success") || path.includes("/contactless/payment-failed")) {
        // if(!isLoggedIn){
        //   return getStateFromPath(path, config);
        // }

        onActiveDrawerChange(path)
        
        return { routes: [{ name: "Home" }] };
      }  else if (path.includes("/document")) {
        if(!isLoggedIn){
          dispatch(dashboardActions.setDocumentId(path.replace("/document/", "")));
          return getStateFromPath(path, config);
        }

        onActiveDrawerChange("PDF Viewer");
        viewDocumentRequest({id: path.replace("/document/", "")});
  
        return {
          routes: [{ name: "Home" }],
        };
      } else if (path.includes('?q=user-confirmation&token')) {
        const params: any = qs.parse(path.replace('/?', ''));

        return {
          routes: [{ name: 'SignIn' }, { name: 'verification-check', params }],
        };
      }

      return getStateFromPath(path, config);
    },
  }
};

export default linking;
