import { useAppSelector } from '@src/ducks/ducksHook';
import { selectedAuthRole } from '@src/ducks/slices/auth.slice';
import { AdminList } from '@src/utils/mockdata/AdminList';
import { largeSize } from '@src/utils/screensize-helper';
import React, { useContext, useState } from 'react';
import { Text, View } from 'react-native';
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from "react-native-reanimated";
import { DEVICE_SIZES, useDeviceSize } from "rn-responsive-styles";
import DrawerItem from "../drawer-component/drawer-list/drawer-item";
import { DrawerContext } from '../DrawerProvider';
import { DrawerComponentContext, useDrawerComponentContext } from "./provider";
import useStyles from './styles.css';


const DrawerList = () => {
  const styles = useStyles();
  const isLargeSize = largeSize();
  const device_size = useDeviceSize();
  const role = useAppSelector(selectedAuthRole);
  const { animated } = useDrawerComponentContext();
  const { onMinimizeState } = useContext(DrawerComponentContext);
  const { drawerList, activeTab, onActiveTab } = useContext(DrawerContext);
  const [hover, setHover] = useState(new Array<boolean>(drawerList.length).fill(false));

  const handleHover = React.useCallback((eventName: string, idx: number) => {
    const isHover = eventName === "hoverIn";
    setHover((prevState) => ({
      ...prevState,
      [idx]: isHover,
    }));
  },[]);

  const onToggle = (itemName : string) => {
    if (DEVICE_SIZES.XS === device_size || DEVICE_SIZES.SM === device_size || DEVICE_SIZES.MD === device_size) {
      onMinimizeState();
    }
    onActiveTab(itemName);
  };

  const animatedStyle = useAnimatedStyle(() => {
    const width = interpolate(
      animated.value,
      [360, 110],
      [360, 0],
      Extrapolation.CLAMP,
    );

    const height = interpolate(
      animated.value,
      [360, 110],
      [40, 0],
      Extrapolation.CLAMP,
    );

    const paddingLeft = interpolate(
      animated.value,
      [360, 110],
      [isLargeSize ? 20.25 : 23, isLargeSize ? 21.39 : 29],
      Extrapolation.CLAMP,
    );

    return {
      width,
      height,
      paddingLeft,
    };
  }, [isLargeSize]);

  if (role !== 'admin') return null;
  
  return (
    <View>
    <View style={styles.itemSeparator} />
      <View style={[styles.v_margintop, styles.v_padding_bottom]}>
        <Animated.View style={[animatedStyle, { overflow: 'hidden' }]}>

          <Text style={[styles.adminTabs]}>Admin Menu</Text>

        </Animated.View>
        {AdminList.map((label: string, index: number) => {
          const isHover = hover[index];
          const isActive = label === activeTab;

          return (
            <>
              <DrawerItem
                key={`tabs${index}`}
                isActive={isActive}
                isHover={isHover}
                item={label}
                onPress={() => onToggle(label)}
                onPressIn={() => handleHover("hoverIn", index)}
                onPressOut={() => handleHover("hoverOut", index)}
                onHoverIn={() => handleHover("hoverIn", index)}
                onHoverOut={() => handleHover("hoverOut", index)}
              />
            </>
          );
        })}
      </View>
    </View>
  );
};

export default DrawerList;
