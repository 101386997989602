const ValidID = [
  'UMID',
  'Driver’s License',
  'Professional Regulation Commission (PRC) ID',
  'Passport',
  'Senior Citizen ID',
  'SSS ID',
  'COMELEC / Voter’s ID',
  'NBI Clearance',
  'Integrated Bar of the Philippines (IBP) ID',
  'BIR (TIN)',
  'Barangay ID',
  'Philippine Postal ID (issued 2015 onwards)',
  'Phil-health ID (digitize PVC)',
  'Firearms License from Philippine National Police (PNP)',
  'Persons with Disabilities (PWD) ID from Local Government Unit (LGU)',
  'Company IDs issued by Private Entities or Institutions registered with/or supervised or regulated either by the BSP, SEC or IC'
];

export default ValidID;
