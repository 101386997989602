import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    modal_container: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.blurBG,
    },
    modal_body: {
      width: 420,
      borderRadius: 5,
      paddingVertical: 50,
      backgroundColor: colors.white,
      alignItems: 'center',
      justifyContent: 'center',
    },
    t_title: {
      fontSize: 14,
      color: colors.black3,
      fontFamily: 'DMSans-Bold',
      textAlign: 'center',
      marginBottom: 20
    },
    t_description: {
      fontSize: 14,
      color: colors.black2,
      fontFamily: "DMSans",
      textAlign: 'center',
    },
    btn_mask: {
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
  }
);

export default useStyles;
