import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    drawer_tab_container: {
      height: 60,
      minWidth: 250,
      paddingLeft: 23,
      flexDirection: "row",
      alignItems: "center",
    },
    drawer_icons: {
      height: 20,
      width: 20,
      marginRight: 18,
      tintColor: colors.purple,
    },
    activeBG: {
      backgroundColor: colors.white,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },
    activeHover: {
      backgroundColor: "rgba(255,255,255,0.1)",
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },
    drawer_tab_label: {
      fontSize: 18,
      lineHeight: 24,
      marginLeft: 20,
      fontFamily: "DMSans",
      color: colors.white,
    },
    v_margintop: { 
      marginTop: 37,
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      drawer_tab_container: { paddingLeft: 17.25, height: 45 },
      drawer_tab_label: { fontSize: 14, lineHeight: 16, marginLeft: 17 },
      activeBG: { borderTopLeftRadius: 15, borderBottomLeftRadius: 15 },
      activeHover: { borderTopLeftRadius: 15, borderBottomLeftRadius: 15 },
      drawer_icons: {
        height: 12,
        width: 12,
        marginRight: 16,
      },
    },
    [DEVICE_SIZES.MD]: {
      drawer_tab_label: { fontSize: 14, lineHeight: 14 },
      drawer_icons: {
        height: 12,
        width: 12,
        marginRight: 15,
      },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      v_margintop: { marginTop: 15 },
      drawer_tab_label: { fontSize: 12, lineHeight: 14 },
      drawer_tab_container: { paddingHorizontal: 24 },
      drawer_icons: {
        height: 12,
        width: 12,
        marginRight: 10,
      },
    },
  }
);

export default useStyles;
