import React, { useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import InputAdornment from '@mui/material/InputAdornment';
import { View, Text, Image } from 'react-native';
import useStyles from './styles.css';
import images from '@assets/images';
import colors from '@assets/colors';
import _ from 'lodash';

function DatePicker01(props: any) {
  const styles = useStyles();
  const errLabelStyle = props.error ? { color: colors.red } : {};
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <View style={[styles.container, props.style]}>
        {!!props.label && (
          <Text
            numberOfLines={1}
            style={[styles.label, props.labelStyle, errLabelStyle]}
          >
            {props.label}
          </Text>
        )}
        <DatePicker
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          disabled={props.disabled}
          value={props.value}
          disableOpenPicker={props.disableOpenPicker || false}
          onChange={(newValue) => {
            props.onChange(newValue);
            handleClose();
          }}
          slotProps={{
            popper: {
              disablePortal: true,
            },
            textField: {
              size: 'small',
              fullWidth: props.isFullWidth || false,
              variant: 'outlined',
              error: !_.isEmpty(props.error),
              // helperText: props.error,
              helperText: (
                <View style={{ color: 'red' }}>
                  {props.error}
                </View>
              ),
              FormHelperTextProps: {
                sx: { ...props.errorStyle },
              },
              InputProps: {
                sx: { ...styles.inputSx, ...props.inputStyle },
                endAdornment: !props.disableOpenPicker ? (
                  <InputAdornment position="end">
                    <Image
                      style={styles.i_schedule}
                      source={images.ic_schedule}
                      resizeMode="contain"
                      onClick={handleOpen}
                    />
                  </InputAdornment>
                ) : null,
              }
            },
          }}
        />
      </View>
    </LocalizationProvider>
  );
}

export default DatePicker01;
