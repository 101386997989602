import colors from "@assets/colors";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {

    // DROP-DOWN
    container: {
      height: 50,
      marginTop: 20,
      width: '100%',
      borderWidth: 1,
      borderRadius: 8,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 20,
      borderColor: colors.black4,
      backgroundColor: colors.white,
    },
    v_circle: {
      width: 24,
      height: 24,
      borderRadius: 24 / 2,
      backgroundColor: colors.black,
    },
    t_label: {
      flex: 1,
      fontFamily: 'DMSans',
      fontSize: 15,
      lineHeight: 20,
      marginLeft: 20,
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      v_circle: {
        width: 15,
        height: 15,
        borderRadius: 15 / 2,
      },
      t_label: {
        fontSize: 11,
        lineHeight: 14,
        marginLeft: 12,
      },
    },
    [maxSize(DEVICE_SIZES.MD)]: {
    },
  }
);

export default useStyles;
