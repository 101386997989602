import { useNavigation } from '@react-navigation/native';
import Button from '@src/components/button/Button02';
import { useDashboardService } from '@src/ducks/hooks';
import React, { useContext } from 'react';
import { Text, View } from 'react-native';
import { DrawerContext } from '../DrawerProvider';
import { RequestContext } from '../RequestProvider';
import CreateRequestCard from './CreateRequestCard';
import useStyles from './styles.css';
import { useSignsecureService } from '@src/ducks/hooks';

const CreateRequestModal = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { onDrawerEnable } = useDashboardService();
  const { onActiveTab } = React.useContext(DrawerContext);
  const {
    isCreateRequestVisible,
    onCreateRequestPress,
    onLivePress,
    onSubmitDocumentPress,
    onSetTotalSteps,
  } = useContext(RequestContext);
  const { viewDocumentRequest } = useSignsecureService();

  const onPress = (type?: string) => {
    if (type === 'live') {
      // onLivePress();
      onActiveTab('LiveRequest');
    } else {
      onActiveTab('ContactLessRequest');
      // onDrawerEnable(false)
      // onSetTotalSteps(2); // total of 3 steps including index 0
      // onSubmitDocumentPress();
    }
    onCreateRequestPress();
    viewDocumentRequest({});
  };

  if (!isCreateRequestVisible) {
    return null;
  }

  return (
    <View style={styles.cr_modal_container}>
      <View style={styles.cr_field_v}>
        <Text style={styles.cr_header}>Create a new request</Text>
        <View style={styles.cr_card_v}>
          <CreateRequestCard
            icon={'camera'}
            title="I want a live notarization session"
            description="Choose from our available partners"
            buttonLabel="Request now"
            onPress={() => onPress('live')}
          />
          <CreateRequestCard
            icon={'document'}
            title="I want to submit a document"
            buttonLabel="Request now"
            onPress={() => onPress('contactless')}
          />
        </View>
        <Button style={styles.cr_btn_cancel} onPress={onCreateRequestPress}>
          <Text style={styles.cr_btn_label}>Cancel</Text>
        </Button>
      </View>
    </View>
  );
};

export default CreateRequestModal;
