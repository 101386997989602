import colors from "@assets/colors";
import { Button01 } from "@src/components/button";
import { useFetchPost } from "@src/utils/api/hooks";
import React from "react";
import Validator from "validator";
import useStyles from "./styles.css";

interface Props {
  email: string;
  isLoading: boolean;
  onSigningIn: () => void;
  onSignInWithFaceID: () => void;
}

const ActionButton = (props: Props) => {
  const styles = useStyles();
  const timeout = React.useRef<any>(null);
  const { data, runRequest } = useFetchPost();

  React.useEffect(() => {
    if(props.email && Validator.isEmail(props.email)){
      timeout.current && clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        timeout.current && clearTimeout(timeout.current);
        runRequest("/auth/face-id", { email: props.email });
      },1000);
    }

    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  },[props.email]);

  return (
    <>
      <Button01
        label="Sign In"
        color={colors.white}
        isLoading={props.isLoading}
        onPress={props.onSigningIn}
        style={styles.btn_signin}
        labelStyle={styles.lbl_signin}
      />
      {data?.isEnable && <Button01
        label="Sign in with UNAWA Face Recognition"
        color={colors.white}
        onPress={props.onSignInWithFaceID}
        style={styles.btn_signin2}
        labelStyle={styles.lbl_signin}
      />}
    </>
  );
};

export default ActionButton;