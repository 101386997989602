import { useRoute } from '@react-navigation/native';
import Loading from '@src/components/loading';
import { useAppSelector } from '@src/ducks/ducksHook';
import { selectedViewDocument } from '@src/ducks/slices/signsecure.slice';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { HeaderContext } from './provider/header';
import useStyles from './styles.css';

// SCREENS
const Identification = React.lazy(() => import('./identification'));
const SubmitDocuments = React.lazy(() => import('./submit-documents'));
const AddParticipants = React.lazy(() => import('./add-participants'));
const AddFields = React.lazy(() => import('./add-fields'));
const ReviewDetails = React.lazy(() => import('./review-details'));
const WaitingApproval = React.lazy(() => import('./waiting-approval'));
const Review = React.lazy(() => import('./submit-to-notary'));
const ChooseNotaryPublic = React.lazy(() => import('./choose-notary-public'));
const Screens = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const document = useAppSelector(selectedViewDocument);  
  const initialActiveIndex = document?.workflow?.status === 'FOR PAYMENT' ? 6 : 0;
  const { activeIndex, onSetCurrentPage } = React.useContext(HeaderContext);

  useEffect(() => {
    if (document?.workflow?.status === 'FOR PAYMENT') {
      onSetCurrentPage(initialActiveIndex)
    }
  }, [document?.workflow?.status]);

  const components = React.useMemo(() => {
    switch (activeIndex) {
      case 0:
        return <Identification />;
      case 1:
        return <SubmitDocuments />;
      case 2:
        return <AddParticipants />;
      case 3:
        return <ChooseNotaryPublic />;
      case 4:
        return <AddFields />;
      case 5:
        return <ReviewDetails />;
      case 6:
        return <Review />;
      case 7:
        return <WaitingApproval />;
    }
  }, [activeIndex]);

  return (
    <View style={styles.scrollview} >
      <React.Suspense fallback={<Loading />}>{components}</React.Suspense>
    </View>
  );
};

export default React.memo(Screens);
