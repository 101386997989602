import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import { StyleSheet } from 'react-native';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorPrimary,
      height: '100vh'
    },
    v_body: {
      // flex: 1,
      width: '100%',
      height: '100%',
      flexDirection: 'row-reverse',
    },
    t_logo: {
      fontFamily: "DMSans",
      fontWeight: "700",
      paddingTop: 0,
      fontSize: 50,  
      color: colors.white, 
    },
    v_signup: {
      zIndex: 2,
      width: '50%',
      justifyContent: 'center',
      paddingHorizontal: '3%',
      backgroundColor: colors.white,
    },
    v2_logo_container: {
      flex: 1,
      width: "50%",
      alignItems: "flex-start",
      justifyContent:"flex-start",
      paddingLeft: 140,
      paddingTop: 160
  },
    v_upper_logo: {
      //* OLD CSS FOR  V_UPPER_LOGO *//
      // width: '100%',
      // height: '0%',
      // position: 'relative',
      // marginTop:  -500,
      // alignItems: 'center',
      // justifyContent: 'flex-end',
      // paddingBottom: '3%',
    },
    v_lower_logo: {
      //* OLD CSS FOR  V_LOWER_LOGO *//
      // width: '100%',
      // height: '59.3%',
      // alignItems: 'center',
      // justifyContent: 'flex-start',
    },
    signInCenter: { textAlign: 'left', marginTop: 30 },
    txtNote1: StyleSheet.flatten([
      TypographyStyle.heading2Bold,
      { textAlign: 'left' },
    ]),
    txtNote3: StyleSheet.flatten([
      TypographyStyle.heading3Bold,
      { textAlign: 'center', fontSize: 30, lineHeight: 33, marginTop: -655, marginLeft: -70},
    ]),
    v_field: { marginTop: 20 },
    txtNote2: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      { marginTop: 16, textAlign: 'left' },
    ]),
    uploadDocText: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      { marginTop: 20, textAlign: 'left' },
    ]),
    txtNoteDocs: StyleSheet.flatten([
      TypographyStyle.uploadDocsText,
      // { textAlign: 'left', flex: 1, marginLeft: 10 },
      { textAlign: 'center', flex: 1, marginTop: 0 },
    ]),
    t_publicnotary: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { textAlign: 'left', color: colors.purple, padding: '0' },
    ]),
    v_wrap_row: { flexDirection: 'row' , paddingRight: 5 },
    // v_flex1: { flex: 1, width: 255, height: 42 },
    v_flex1: { flex: 1 },
    v_flex2: { flex: 1, zIndex: -1000, marginRight: 200 },
    errorStyles: {color: colors.red, fontSize: 10},
    v_divider: { width: 25 },
    v_divider1: { width: '34.5%' },
    v_agree_container: {
      marginTop: 40,
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 75,
    },
    v_wrap_agree: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_agree_box: {
      width: 18,
      height: 18,
      borderWidth: 1,
      borderRadius: 4,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: colors.border2,
    },
    v_agree_box_active: {
      borderColor: colors.black1,
      backgroundColor: colors.black1,
    },
    t_remember_me: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: 90,
        marginTop: 10,
        marginLeft: 3
      },
    ]),
    t_forgot_password: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        fontWeight: '700',
        color: colors.orange1,
      },
    ]),
    t_signup: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginTop: 35,
        textAlign: 'center',
        alignSelf: 'center',
        color: colors.black3,
      },
    ]),
    t_terms: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.purple4,
        fontWeight: '400',
      },
    ]),
    t_terms2: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.purple,
        fontWeight: '700',
      },
    ]),

    t_error_message: {
      marginTop: 12,
      fontFamily: 'DMSans',
      fontSize: 13,
      color: colors.red,
      textAlign: 'center',
    },
    btn_signup: { marginTop: 30 },
    lbl_signup: StyleSheet.flatten([
      TypographyStyle.buttonText,{color: colors.colorPrimary,}]),
    v_height: { height: 50 },

    // LOGO COMPONENT
    i_logo: {
      width: 400,
      height: 100,
      alignItems: 'center',
      justifyContent: 'center',
    },
    i_logo_text: {
      fontSize: 41,
      fontWeight: '700',
      lineHeight: 49,
      color: colors.white,
      marginTop: -50,
      marginLeft: -15
    },
    unawa_logo_text: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
      color: '#F4F4F4', 
      fontWeight: '700',
      lineHeight: 18,
      marginTop: -200, 
      marginLeft: -500,
      },
    ]),
    unawa_logo_text1: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
      color: colors.white, 
      fontWeight: '400',
      lineHeight: 18,
      fontSize: 12,
      marginTop: -180, 
      marginLeft: -330
      },
    ]),
    unawa_logo: {
      width: 80,
      height: 18,
      marginTop: -200,
      marginLeft: -155
    },
    bg_image: {
      width: 500,
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    bg_image2: {
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dropdown_style: {flex: 1, marginTop: 24},
    t_menu_item: {
      flex: 1,
      fontSize: 14,
      lineHeight: 16,
      color: colors.black,
      fontFamily: "DMSans",
    },
    //IMAGE BG
    i_bg: {
      // ...StyleSheet.absoluteFillObject,
      zIndex: -1,
      width: '100%',
      marginLeft: '100%',
      flex: 1,
      height: '100%',
      transform: [{ scaleX: 2 }],
    },
    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { marginBottom: 10, alignSelf: 'center', color: colors.purple },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([TypographyStyle.copyRightButton]),

    // BACKGROUND COMPONENT
    linearStyle: {
      position: 'absolute',
      zIndex: -1,
      width: '100%',
      height: '100%',
    },
    linearStyle_portrait: { display: "none" },

    // Email Sent
    t_email_sent: StyleSheet.flatten([
      TypographyStyle.heading2Bold,
      { textAlign: 'center' },
    ]),
    t_email_sent_notes: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { fontSize: 20, marginTop: 16, textAlign: 'center' },
    ]),
    t_email_sent_notes1: StyleSheet.flatten([
      TypographyStyle.heading3Bold,
      { fontSize: 36, lineHeight: 52, textAlign: 'center', color: colors.black2 },
    ]),
    t_email_sent_notes2: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginTop: 35,
        fontSize: 15,
        textAlign: 'center',
        color: colors.black3,
      },
    ]),
    btn_backtologin: {
      marginTop: 30,
      // backgroundColor: 'black',
      backgroundColor: colors.purple4,
      marginHorizontal: 'auto',
      width: 220
    },
    t_marginTopBottom: {
      marginTop: 25,
      marginBottom: 50
    },
    t_marginBottom: {
      marginBottom: 50,
    },

    // UPLOAD MASK
    v_upload_mask_container: {
      // flex: 1,
      // height: 40,
      marginTop: 20,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_upload_mask_row: {
      width: 15,
      height: 15,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.purple,
    },
    t_upload_mask_plus: {
      fontFamily: 'Roboto',
      fontSize: 15,
      color: 'white',
    },

    // BACK BUTTON
    btn_style: {
      marginTop: 50,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    t_back: { fontFamily: 'DMSans-Bold', color: colors.purple, fontSize: 18 },
    tooltip: {
      position: "absolute",
      left: "100%",
      top: "-150%",
      textAlign: "left",
      lineHeight: 20,
      backgroundColor: colors.purple5,
      color: "black",
      padding: 20,
      borderRadius: 8,
      width: 220,
      height: 115,
      fontFamily: 'DMSans',
      fontWeight: '400',
      fontSize: 12,
    },
    inputBorderStyle : {
      minHeight: 40
    },
    // Date picker
    date_picker_input_style: {
      flex: 1,
      marginTop: 1,
      width: '100%',
      padding: '3px 3px'
    },
    date_picker_style: {
      flex: 1,
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: 12
    },
    date_picker_error_style: {
      position: 'absolute',
      top: -20,
      right: 0,
      fontSize: 10, 
      fontStyle: "italic",
      borderWidth: 2,
      fontFamily: "DMSans-Bold"
    },
    date_picker_input_sx: {
      width: '100%'
    }
  },
  {
    [DEVICE_SIZES.XL]: {
      i_logo: {
        width: 41,
        height: 49,
        marginTop: -220,
        marginLeft: -70
      },
      v_agree_container: {marginTop: 0},
      bg_image: {
        width: 800,
      },
      txtNote3: {
        fontSize: 65,
        lineHeight: 60,
        textAlign: 'left'
      },
      txtNote1: {
        color: colors.purple4,
        textAlign: 'center',
        fontSize: 25
      },
      txtNote2:{ 
        fontSize: 13,
        fontWeight: '700',
        lineHeight: 0,
        marginTop: 30,
        marginBottom: 15
      },
      uploadDocText:{ 
        fontSize: 13,
        fontWeight: '700',
        lineHeight: 0,
        marginTop: 40,
      },
      t_publicnotary: {
        textAlign: 'center',
        fontSize: 12,
        lineHeight: 5,
        color: colors.black2,
      },
      txtNoteDocs: {textAlign: 'left', paddingLeft: 10, fontSize: 12},
      v_flex1: {flex: 1, marginTop: 10},
      v_flex2: {flex: 1, marginTop: 10, zIndex: -1000},
      v_upload_mask_container: {marginBottom: 20},
      t_email_sent: {fontSize: 36, marginTop: -50, marginBottom: 30},
      t_email_sent_notes: {marginBottom: 30}
    },
    [maxSize(DEVICE_SIZES.LG)]: {
      i_logo: {
        width: 259,
        height: 50,
      },
      t_logo: { fontSize: 28.75, lineHeight: 30, marginTop: 5 },
      bg_image: {
        width: 460,
      },
      txtNote3: {
        fontSize: 9,
        lineHeight: 51,
        textAlign: 'left'
      },
    },
    [DEVICE_SIZES.MD]: {
      linearStyle_portrait: { display: "none" },
      i_logo: {
        width: 300,
        height: 100,
      },
      v2_logo_container: {
        width: "5%"
        // display: 'none'
      },
      t_logo: { 
        fontSize: 32, 
        lineHeight: 41, 
        marginTop: 15, 
        paddingBottom: 90, 
        fontWeight: "500",
        textAlign: "center"
      },
      unawa_logo_text1: {
        fontSize: 50,
      },
      v_signup: { paddingHorizontal: '4%', marginTop: 0 },
      copyRightStyle: { fontSize: 12 },
      copyRightBtnStyle: { fontSize: 12, lineHeight: 5 },
      txtNote2: {
        textAlign: 'left',
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
      },
      txtNote3: {
        fontSize: 25,
        lineHeight: 60,
        textAlign: 'left'
      },
    },
    [DEVICE_SIZES.SM]: {
      linearStyle_portrait: { display: "none" },
      t_logo: { 
        fontSize: 16, 
        lineHeight: 25, 
        marginTop: 15, 
        paddingBottom: 80, 
        fontWeight: "500",
        textAlign: "center"
      },
      v2_logo_container: {
        // width: "20%"
        display: 'none'
      },
      i_bg: {
        transform: [{ scaleX: 1 }],
      },
      txtNoteDocs: StyleSheet.flatten([
        TypographyStyle.uploadDocsText,
        { textAlign: 'center', flex: 1, marginTop: 0, paddingLeft: 10 },
      ]),
      v_body: {
        width: '80%',
        height: '75%',
        alignItems: 'flex-start',
        marginHorizontal: 20,
        marginBottom: 40,
      },
      v_signup: {
        borderRadius: 8,
        minWidth: '100%',
        maxWidth: '100%',
        paddingVertical: 25,
        marginBottom: 200,
        // paddingVertical: 50,
        paddingHorizontal: 30,
      },
      t_publicnotary: {
          display: 'flex',
          justifyContent: 'center',
          fontSize: 18
      },
      txtNote1: {
        textAlign: 'center',
        fontSize: 22,
        lineHeight: 25,
      },
      txtNote2: {
        textAlign: 'center',
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
      },
      txtNote3: {
        fontSize: 15,
        lineHeight: 60,
        textAlign: 'left'
      },
      copyRightStyle: {
        fontSize: 11,
      },
      copyRightBtnStyle: {
        fontSize: 11,
      },

      t_email_sent: {
        fontSize: 22,
        lineHeight: 25,
      },
      t_email_sent_notes: {
        fontSize: 16,
        lineHeight: 20,
      },
      t_email_sent_notes2: {
        fontSize: 12,
        lineHeight: 20,
      },

      v_wrap_row: {
        flexDirection: 'column',
      },
      v_upload_mask_container: {
        alignSelf: 'center',
      },
    },
    [DEVICE_SIZES.XS]: {
      linearStyle_portrait: { display: "none" },
      t_logo: { 
        fontSize: 20, 
        lineHeight: 25, 
        marginTop: 15, 
        paddingBottom: 90, 
        fontWeight: "500",
        textAlign: "center"
      },
      v2_logo_container: {
        // width: "20%"
        display: 'none'
      },
      i_bg: {
        transform: [{ scaleX: 1 }],
      },
      v_body: {
        width: '90%',
        height: '75%',
        alignItems: 'flex-start',
        marginHorizontal: 20,
        marginBottom: 40,
      },
      v_signup: {
        borderRadius: 8,
        minWidth: '100%',
        maxWidth: '100%',
        paddingVertical: 25,
        marginBottom: 200,
        // paddingVertical: 50,
        paddingHorizontal: 20,
        // height: '100%',
      },
      t_publicnotary: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 16
      },
      txtNote1: {
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 20,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 14,
        lineHeight: 20,
        textAlign: 'center',
      },
      v_agree_box: {
        fontSize: 9,
        color: 'red',
      },
      // v_remember_forgot: { flexDirection: 'column' },
      t_forgot_password: { marginTop: 20 },
      copyRightStyle: {
        color: colors.black3,
        fontSize: 11,
      },
      copyRightBtnStyle: {
        fontSize: 11,
      },

      t_email_sent: {
        fontSize: 20,
        lineHeight: 25,
      },
      t_email_sent_notes: {
        fontSize: 15,
        lineHeight: 20,
      },
      t_email_sent_notes2: {
        fontSize: 12,
        lineHeight: 20,
      },

      v_wrap_row: {
        flexDirection: 'column',
      },
      v_upload_mask_container: {
        alignSelf: 'center',
      },
    },
    
    [maxSize(DEVICE_SIZES.LG)]: {
      v_signup: { paddingHorizontal: '5%'},
      txtNote1: { fontSize: 40, textAlign: 'center' },
      t_publicnotary: { marginTop: 10, textAlign: 'center' },
      txtNote3: { marginTop: 0 },
      v_agree_container: {
        paddingLeft: 0,
        marginTop: 20
      },
      t_remember_me: {
        paddingLeft: 0,
        paddingRight: 0
      },
      v_upload_mask_container: { alignSelf: 'center' },
      txtNoteDocs: StyleSheet.flatten([
        TypographyStyle.uploadDocsText,
        { textAlign: 'center', flex: 1, marginTop: 0, paddingLeft: 10 },
      ]),
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      v_divider: {
        width: 13
      },
      v_signup: {
        marginTop: 0,
        paddingHorizontal: '4%'
      },
      v_lower_logo: {
        width: '100%',
        height: '45.3%',
        marginTop: 'auto'
      },
    },
    [maxSize(DEVICE_SIZES.SM)] : {
      txtNote1: { fontSize: 29, textAlign: 'center' }
    },
  }
);

export default useStyles;
