import { useAppSelector } from '@src/ducks/ducksHook';
import { useDashboardService, useSignsecureService } from '@src/ducks/hooks';
import { selectedAuthRole } from '@src/ducks/slices/auth.slice';
import { selectedActiveDrawer, selectedDocumentID } from '@src/ducks/slices/dashboard.slice';
import { DrawerList } from '@src/utils/mockdata';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface DrawerContextValue {
  drawerList: string[];
  activeTab: string;
  activeTabDrawer: string;
  onActiveTab: (value: string) => void;
  onActiveTabDrawer: (value: string) => void;
  isDrawerVisible: boolean;
  onDrawerStatus: () => void;
  activeSecondScreen: string;
  onSetActiveSecondScreen: (value: string) => void;
  onSearchStateChange: (value: string) => void;
  search: string;
  onAddTeamButton: () => void;
}

const DrawerContext = createContext<DrawerContextValue>({
  search: "",
  drawerList: [],
  activeTab: 'Dashboard',
  activeTabDrawer: 'Dashboard',
  onActiveTab: () => {},
  onActiveTabDrawer: () => {},
  isDrawerVisible: false,
  onDrawerStatus: () => {},
  activeSecondScreen: "",
  onSetActiveSecondScreen: () => {},
  onSearchStateChange: () => {},
  onAddTeamButton: () => {},
} as DrawerContextValue);

interface BoardProviderProps {
  children: React.ReactElement;
}

const DrawerProvider = ({ children }: BoardProviderProps) => {
  const role = useAppSelector(selectedAuthRole);
  const drawerList = DrawerList(role);
  const [search, setSearch] = useState<string>("");
  const documentId = useAppSelector(selectedDocumentID);
  const activeTab = useAppSelector(selectedActiveDrawer);
  const { onActiveDrawerChange } = useDashboardService();
  const { viewDocumentRequest } = useSignsecureService();
  const [isDrawerVisible, setDrawerStatus] = useState(false);
  const [activeSecondScreen, setSecondScreen] = useState<string>("");
  const [activeTabDrawer, setActiveTabDrawer] = useState('Dashboard');
  const [addTeamSuccess, setAddTeamSuccess] = useState<boolean>(false);

  const onActiveTab = useCallback(
    (value: string) => {
      onActiveDrawerChange(value);
      setDrawerStatus(false);
      setSearch("");
    },
    [activeTab]
  );

  const onActiveTabDrawer = useCallback(  // For Drawer only
    (value: string) => {
      setActiveTabDrawer(value);
      setDrawerStatus(false);
    },
    [activeTab]
  );

  const onDrawerStatus = useCallback(() => {
    setDrawerStatus(!isDrawerVisible);
  }, [isDrawerVisible]);

  const onSetActiveSecondScreen = useCallback(
    (value: string) => {
      setSecondScreen(value);
    },[activeSecondScreen]
  );

  const onSearchStateChange = useCallback(
    (value: string) => {
      setSearch(value);
    },[search]
  );

  const onAddTeamButton = useCallback(() => {
    setAddTeamSuccess(!addTeamSuccess);
  }, [addTeamSuccess]);

  const value = {
    search,
    drawerList,
    activeTab,
    onActiveTab,
    isDrawerVisible,
    onDrawerStatus,
    activeTabDrawer,
    onActiveTabDrawer,
    activeSecondScreen,
    onSetActiveSecondScreen,
    onSearchStateChange,
    onAddTeamButton
  };

  useEffect(() => {
    if(documentId){
      onActiveTab("PDF Viewer");
      viewDocumentRequest({id: documentId});
    }
  },[documentId])

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export const useDrawerContext = () => {
  return useContext(DrawerContext);
}

export { DrawerContext, DrawerProvider };

