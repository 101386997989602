const LiveRequest = [
  'Identification',
  'Submit Document',
  'Add Participants',
  'Choose Notary',
  'Add Fields',
  'Review details',
  'Submit to Notary',
  'Waiting for approval',
  // 'Video session',
  // 'Review',
];

export default LiveRequest;
